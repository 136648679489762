import { styled } from "../../theme/styled";
import { FiCalendar } from "react-icons/fi";

export const StyledCalendarContainer = styled("div", {
  position: "relative",
  userSelect: "none",
});

export const StyledDate = styled("div", {
  shadow: 1,
  borderRadius: 24,
  display: "flex",
  justifyContent: "space-between",
});

export const StyledText = styled("p", {
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "22px",
  padding: "12px 18px",
});

export const StyledCalendar = styled("input", {
  position: "absolute",
  visibility: "hidden",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

export const StyledIcon = styled(FiCalendar, {
  width: 24,
  height: 24,
  color: "$white",
});

export const StyledContainerIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gradient: "$primary",
  borderRadius: "50%",
  flexShrink: 0,
  flexGrow: 0,
  width: 48,
  height: 48,
});

export const StyledCalendarIOS = styled("input", {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  shadow: 1,
  borderRadius: 24,
  height: 48,
  width: "100%",
  outline: "none",
  fontSize: 16,
  paddingLeft: 12,
  background: "$white",
  "-webkit-appearance": "none",
  color: "$dark",
  "&::-webkit-calendar-picker-indicator": {
    background: "url('/img/calendar-btn.svg') no-repeat center center",
    borderRadius: "50%",
    width: 48,
    height: 48,
    padding: 0,
  },
});
