import { getUser } from "../firebase/users";

const updateTurnoCache = (cache, turno, isNew = false) => {
  if (isNew) {
    cache.current.push(turno);
  } else {
    // Update turno by id
    const index = cache.current.findIndex((t) => t.id === turno.id);
    if (index !== -1) {
      cache.current[index] = turno;
    }
  }
};

const removeTurnoCache = (cache, turnoId) => {
  const index = cache.current.findIndex((t) => t.id === turnoId);
  if (index !== -1) {
    cache.current.splice(index, 1);
  }
};

const updateUserCache = async (cache, turno, user, admin) => {
  // Si el turno tiene un usuario y no está en el cache...
  if (turno.user?.id && !cache.current[turno.user.id]) {
    // Si el usuario es el mismo que el usuario actual...
    if (turno.user.id === user?.uid) {
      cache.current[turno.user.id] = user;
    }
    // Si el usuario es admin obetener desde firebase
    else if (admin) {
      // solo si es admin se obtiene el usuario de la base de datos
      const turnoUser = await getUser(turno.user.id);
      cache.current[turno.user.id] = turnoUser;
    }
  }
};

export { updateTurnoCache, removeTurnoCache, updateUserCache };
