import { styled } from "../../theme/styled";

export const StyledTurnoCard = styled("div", {
  userSelect: "none",
  position: "relative",
  height: 76,
  marginBottom: 12,
  borderRadius: 16,
  boxShadow: "0px 1px 53px rgba(0, 0, 0, 0.1)",
  backgroundColor: "$card",
  transition: "background 0.2s ease-in-out",
  "&:active": {
    backgroundColor: "$light",
  },
  variants: {
    loading: {
      true: {
        opacity: 0.8,
      },
    },
  },
});

export const StyledHora = styled("p", {
  position: "absolute",
  top: "9px",
  left: "12px",
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "27px",
});

export const StyledHoraFin = styled("span", {
  fontWeight: 500,
  fontSize: 16,
  color: "$dark",
  lineHeight: "27px",
  marginLeft: 4,
});

export const StyledName = styled("p", {
  position: "absolute",
  bottom: "12px",
  left: "12px",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "22px",
});

export const StyledEstado = styled("p", {
  position: "absolute",
  top: "12px",
  right: "12px",
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "27px",
  variants: {
    estado: {
      OCUPADO: {
        color: "$primary",
      },
      DISPONIBLE: {
        color: "$secondary",
      },
      PENDIENTE: {
        color: "$yellow",
      },
      DESACTIVADO: {
        color: "$gray",
      },
    },
  },
});
