import React, { useState } from "react";
import Title from "../components/Title/Title";
import TextInput from "../components/TextInput/TextInput";
import Button from "../components/Button/Button";
import EmailSigninLayout from "../components/EmailSignin/EmailSigninLayout";
import { useNavigate } from "react-router";
import { signupEmail } from "../utils/login";
import Paragraph from "../components/Paragraph/Paragraph";

const SignupPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();

    if (loading) return;

    if (!name.trim() || !email || !password || !passwordConfirmation || !phone) {
      setError("Por favor complete todos los campos");
      return;
    }

    if (name.trim().length <= 5) {
      setError("El nombre debe tener al menos 5 caracteres, por favor ingrese su nombre y apellido");
      return;
    }

    if (phone.trim().length <= 8) {
      setError("El teléfono debe tener al menos 8 caracteres, por favor ingrese su número de teléfono con codigo de area");
      return;
    }

    if (password.length < 8) {
      setError("La contraseña debe tener al menos 8 caracteres");
      return;
    }

    if (password !== passwordConfirmation) {
      setError("Las contraseñas no coinciden");
      return;
    }

    try {
      setLoading(true);
      await signupEmail(email, password, name, phone);
    } catch (e) {
      console.log(e.code);
      let message = "Ocurrió un error al registrarse, por favor intenta de nuevo.";
      switch (e.code) {
        case "auth/email-already-in-use":
          message = "El email ingresado ya está registrado, por favor inicia sesión o intenta con otro email.";
          break;
        case "auth/invalid-email":
          message = "El email ingresado no es válido, por favor verifique que el email sea correcto.";
          break;
        default:
          break;
      }
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailSigninLayout>
      <EmailSigninLayout.Form onSubmit={handleSignup}>
        <input type="submit" hidden />
        <EmailSigninLayout.Title>
          <Title size="xxl">Registrarse</Title>
        </EmailSigninLayout.Title>
        <EmailSigninLayout.Input>
          <TextInput
            label={"Nombre y Apellido"}
            name="name"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ingrese su nombre completo"
            disabled={loading}
          ></TextInput>
          <TextInput
            label={"Teléfono"}
            name="phone"
            value={phone || ""}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Ingrese su numero de teléfono"
            disabled={loading}
          ></TextInput>
          <TextInput
            label={"Correo Electrónico"}
            name="email"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ingrese su email"
            type="email"
            disabled={loading}
          ></TextInput>
          <TextInput
            label={"Contraseña"}
            name="password"
            type="password"
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Ingrese su contraseña"
            disabled={loading}
          ></TextInput>
          <TextInput
            label={"Confirmar contraseña"}
            name="passwordConfirmation"
            type="password"
            value={passwordConfirmation || ""}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="Confirme su contraseña"
            disabled={loading}
          ></TextInput>
          <EmailSigninLayout.ErrorContainer>{error ? error : ""}</EmailSigninLayout.ErrorContainer>
        </EmailSigninLayout.Input>
        <EmailSigninLayout.FooterItem>
          <Button color="white" disabled={loading} onClick={handleSignup}>
            Registrarse
          </Button>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Button size="xs" onClick={() => navigate("/signin")} color="linkWhite" disabled={loading}>
            Ingresar con una cuenta de Google o Apple
          </Button>
        </EmailSigninLayout.FooterItem>
        <Paragraph size="sm" margin="both" align="center">
          Antes de registrarte puedes leer nuestras{" "}
          <EmailSigninLayout.PolicyLink to="/policy">Politicas de privacidad</EmailSigninLayout.PolicyLink>
        </Paragraph>
      </EmailSigninLayout.Form>
    </EmailSigninLayout>
  );
};

export default SignupPage;
