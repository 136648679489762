import { styled } from "../../theme/styled";

export const StyledContainerDay = styled("div", {
  overflowX: "auto",
  position: "relative",
  height: 80,
  borderRadius: 16,
  backgroundColor: "$card",
  shadow: 1,
  display: "flex",
  flexDirection: "row",
  userSelect: "none",
});

export const StyledText = styled("p", {
  fontWeight: 600,

  variants: {
    size: {
      sm: {
        fontSize: 14,
        lineHeight: "19px",
      },
      md: {
        fontSize: 24,
        lineHeight: "26px",
      },
    },
  },
});

export const StyledDia = styled("div", {
  flex: "1 0 50px",
  textAlign: "center",
  padding: "18px 0",
  zIndex: 10,
  transition: "color 0.2s ease-in-out",
  variants: {
    active: {
      true: {
        color: "$card",
      },
      false: {
        color: "$black",
      },
    },
  },
});

export const StyledSelectedBackground = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  gradient: "$primary",
  zIndex: 1,
  borderRadius: 16,
  transition: "transform 0.2s ease-in-out",
});
