import {
  StyledCardContainer,
  StyledFacturacionContainer,
  StyledTitleFacturacion,
} from "./FacturacionLayout.styles";

const Facturacion = StyledFacturacionContainer;

Facturacion.Title = StyledTitleFacturacion;

Facturacion.PeriodContainer = StyledCardContainer;

export default Facturacion;
