import { styled } from "../../theme/styled";
import { MdDelete } from "react-icons/md";

export const StyledCardReserva = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  shadow: 1,
  borderRadius: "16px",
  padding: "12px",
  position: "relative",
});

export const StyledClub = styled("span", {
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "26px",
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StyledData = styled("span", {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  marginTop: "6px",
  marginBottom: "6px",
  color: "$dark",
});

export const StyledReservaEstado = styled("p", {
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "20px",
  variants: {
    estado: {
      OCUPADO: {
        color: "$primary",
      },
      DISPONIBLE: {
        color: "$secondary",
      },
      PENDIENTE: {
        color: "$yellow",
      },
      DESACTIVADO: {
        color: "$gray",
      },
      ELIMINADO: {
        color: "$gray",
      },
    },
  },
});

export const StyledLogoReserva = styled("img", {
  height: "64px",
  width: "64px",
  borderRadius: "50%",
  shadow: 1,
});

export const StyledLogoContainer = styled("div", {
  display: "flex",
  flexGrow: "0",
});

export const StyledTextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginLeft: "16px",
  width: "calc(100% - 80px)",
});

export const StyledDropdownContainer = styled(MdDelete, {
  position: "relative",
});
