import React, { useRef } from "react";
import { isIOS } from "../../utils/ios";
import {
  StyledCalendar,
  StyledCalendarContainer,
  StyledCalendarIOS,
  StyledContainerIcon,
  StyledDate,
  StyledIcon,
  StyledText,
} from "./AdminCalendar.styles";

const diasSemana = ["Dom.", "Lun.", "Mar.", "Mié.", "Jue.", "Vie.", "Sáb."];
const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const dateFormatted = (date) => {
  const dateObj = new Date(date);
  // take into account the timezone
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());

  const dia = dateObj.getDate();
  const mes = meses[dateObj.getMonth()];
  const anio = dateObj.getFullYear();
  const diaSemana = diasSemana[dateObj.getDay()];
  return `${diaSemana} ${dia} de ${mes} de ${anio}`;
};

const AdminCalendar = ({ diaSeleccionado, onSelectDay }) => {
  const inputRef = useRef();

  if (isIOS()) {
    return (
      <StyledCalendarIOS
        type="date"
        value={diaSeleccionado}
        onChange={(e) => {
          if (e.target.value) {
            onSelectDay(e.target.value);
          } else {
            const today = new Date();
            today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
            const todayStr = today.toISOString().split("T")[0];
            onSelectDay(todayStr);
          }
        }}
      ></StyledCalendarIOS>
    );
  }

  return (
    <StyledCalendarContainer onClick={() => inputRef.current?.showPicker()}>
      <StyledDate>
        <StyledText>{dateFormatted(diaSeleccionado)}</StyledText>
        <StyledContainerIcon>
          <StyledIcon />
        </StyledContainerIcon>
      </StyledDate>
      <StyledCalendar
        ref={inputRef}
        type="date"
        value={diaSeleccionado}
        onChange={(e) => {
          if (e.target.value) {
            onSelectDay(e.target.value);
          } else {
            const today = new Date();
            today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
            const todayStr = today.toISOString().split("T")[0];
            onSelectDay(todayStr);
          }
        }}
      ></StyledCalendar>
    </StyledCalendarContainer>
  );
};

export default AdminCalendar;
