import { styled } from "../../theme/styled";

export const StyledEnlaceLayout = styled("div", {
  display: "grid",
  height: "100%",
  gridTemplateRows: "auto 1fr",
});

export const StyledEnlaceLayoutBody = styled("div", {
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
  gap: 12,
  height: "100%",
  overflow: "hidden",
});

export const StyledHorariosLayoutButton = styled("div", {
  padding: "0 16px",
});

export const StyledEnlaceLayoutTitle = styled("div", {
  marginTop: 16,
  padding: "0 16px",
  "& h6": {
    margin: 0,
  },
});

export const StyledEnlaceLayoutEnlaces = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 12,
  overflow: "auto",
  padding: "16px",
});
