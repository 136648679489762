const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const fechaSinHora = (fecha) => fecha.toISOString().substr(0, 10);

const fechasIguales = (fecha1, fecha2) =>
  fecha1.getDate() === fecha2.getDate() &&
  fecha1.getMonth() === fecha2.getMonth() &&
  fecha1.getFullYear() === fecha2.getFullYear();

const fechaPasada = (fechaStr, hora, timezoneOffset) => {
  const today = new Date();
  today.setHours(today.getHours() + timezoneOffset / 60);
  const todayStr = today.toISOString();
  const turnoStr = `${fechaStr}T${hora}:00.000Z`;
  return turnoStr < todayStr;
};

const formatDate = (fecha) => {
  const date = fecha?.split("-");
  if (date) {
    return `${date[2]}/${date[1]}/${date[0]}`;
  } else {
    return "";
  }
};

const formatLongDate = (fecha) => {
  const date = new Date(fecha);
  return `${days[date.getDay()]} ${date.getDate()} de ${months[date.getMonth()]}`;
};

const monthYearStr = (fechaStr) => {
  const fecha = fechaStr?.split("-");
  if (fecha) {
    return `${months[parseInt(fecha[1]) - 1]} ${fecha[0]}`;
  } else {
    return "";
  }
};

const pan = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
};

const formatTimestamp = (timestamp) => {
  try {
    const date = timestamp.toDate();
    return `${days[date.getDay()]} ${date.getDate()} de ${months[date.getMonth()]} a las ${pan(date.getHours())}:${pan(
      date.getMinutes()
    )}`;
  } catch {
    return "Información no diponible";
  }
};

export { fechaSinHora, fechasIguales, fechaPasada, formatDate, monthYearStr, formatLongDate, formatTimestamp };
