import {
  ContentPart,
  FullPage,
  InfoButton,
  PicturePart,
  StyledRankingContainer,
  TagsContainer,
} from "./ClubLayout.styles";

const ClubLayout = FullPage;

ClubLayout.PicturePart = PicturePart;

ClubLayout.ContentPart = ContentPart;

ClubLayout.TagsContainer = TagsContainer;

ClubLayout.InfoButton = InfoButton;

ClubLayout.RankingContainer = StyledRankingContainer;

export default ClubLayout;
