import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

const useAccountVerified = () => {
  const [accountVerified, setAccountVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (externalUser) => {
      if (externalUser) {
        setAccountVerified(externalUser.emailVerified);
      }
    });

    return () => unsubscribe();
  }, []);

  return accountVerified;
};

export default useAccountVerified;
