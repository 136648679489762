import { styled } from "../../theme/styled";

export const StyledTitle = styled("h6", {
  color: "$dark",
  marginBottom: 12,
  userSelect: "none",
  variants: {
    size: {
      sm: { fontWeight: 500, fontSize: "16px", lineHeight: "22px" },
      lg: { fontWeight: 700, fontSize: "20px", lineHeight: "27px" },
      xl: { fontWeight: 700, fontSize: "24px", lineHeight: "33px" },
      xxl: { fontWeight: 700, fontSize: "32px", lineHeight: "44px" },
    },
  },
});
