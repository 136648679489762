import React, { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { StyledDropdown, StyledDropdownContainer, StyledOption, StyledOptionContainer } from "./Dropdown.styles";

const Dropdown = ({ options }) => {
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });

  return (
    <StyledDropdownContainer>
      <StyledDropdown
        onClick={(e) => {
          setShowDropdown(true);
        }}
      />
      {showDropdown && (
        <StyledOptionContainer ref={ref}>
          {options.map((option) => (
            <StyledOption
              key={option.label}
              onClick={() => {
                setShowDropdown(false);
                option.onClick();
              }}
            >
              {option.label}
            </StyledOption>
          ))}
        </StyledOptionContainer>
      )}
    </StyledDropdownContainer>
  );
};

export default Dropdown;
