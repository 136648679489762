import { styled } from "../../theme/styled";

export const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
    alignItems: "center",
});

export const StyledLogo = styled("img", {
  width: 64,
  height: 64,
  borderRadius: 16,
});

export const StyledClubName = styled("h1", {
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
});

export const StyledDireccion = styled("p", {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "19px",
});

export const StyledDataContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
    marginLeft: 12,
});
