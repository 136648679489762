import { FiBell } from "react-icons/fi";
import { styled } from "../../theme/styled";

export const StyledNotificationContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

export const StyledIcon = styled(FiBell, {});

export const StyledCount = styled("span", {
  position: "absolute",
  color: "$white",
  gradient: "$primary",
  borderRadius: "10em",
  padding: "2px 6px",
  minWidth: "21px",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: "bold",
  boxShadow: "white 0 0 0 2px",
  bottom: "12px",
  left: "12px",
});
