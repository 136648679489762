import { useEffect } from "react";
import { useNavigate } from "react-router";

const useValidateClub = (club) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (club && !club.visible) {
      navigate("/");
    }
  }, [club, navigate]);
};

export default useValidateClub;
