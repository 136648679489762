import { styled } from "../../theme/styled";

export const StyledContainerText = styled("div", {
  position: "absolute",
  top: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  borderRadius: "8px",
  shadow: 4,
  width: "250px",
  padding: "8px",
  display: "flex",
  backgroundColor: "$white",
  opacity: 0,
  visibility: "hidden",
  transition: "none 0.1s ease-in-out",
  transitionProperty: "opacity, visibility",
  transitionDelay: "0.3s",
});

export const StyledTextTooltip = styled("span", {
  fontSize: "14px",
  lineHeight: "18px",
  color: "$dark",
  overflow: "hidden",
});

export const StyledContainerTooltip = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  marginLeft: "8px",
  "&:hover": {
    //mostrar el tooltip
    [StyledContainerText]: {
      opacity: 1,
      visibility: "visible",
    },
  },
});

export const StyledTooltip = styled("div", {});
