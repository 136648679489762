import React, { useState } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import ActionAlert from "../ActionAlert/ActionAlert";
import { StyledIcon, StyledText, StyledUpdateMessageMajor } from "./UpdateMessage.styles";

const UpdateMessage = ({ version }) => {
  const [visible, setVisible] = useState(true);

  if (version === "major") {
    return (
      <StyledUpdateMessageMajor>
        <StyledIcon />
        <StyledText>
          Hay una nueva versión disponible, cierre la aplicación y vuelva a abrirla para actualizar
        </StyledText>
      </StyledUpdateMessageMajor>
    );
  } else if (version === "minor") {
    return (
      <ActionAlert
        text="Hay una nueva versión disponible, cierre la aplicación y vuelva a abrirla para actualizar"
        icon={FiDownloadCloud}
        handleClose={() => {
          setVisible(false);
        }}
        visible={visible}
      />
    );
  }
  return null;
};

export default UpdateMessage;
