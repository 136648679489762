import React, { useEffect } from "react";
import {
  StyledDescription,
  StyledDescriptionContainer,
  StyledHora,
  StyledImg,
  StyledNotificationContainer,
  StyledRead,
  StyledReadContainer,
  StyledTextContainer,
} from "./NotificationCard.styles";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import { updateNotification } from "../../firebase/notifications";
import { useNavigate } from "react-router";
import { notificacionTypes } from "../../utils/notificationTypes";
import { getUser } from "../../firebase/users";
import { getClub } from "../../firebase/clubs";

TimeAgo.addDefaultLocale(es);

const timeAgo = new TimeAgo("es-AR");

const NotificationCard = ({ notification }) => {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = React.useState(null);

  useEffect(() => {
    if (notification.sender && notification.type === notificacionTypes.reserva) {
      getUser(notification.sender.id)
        .then((user) => {
          setImgUrl(user?.photoURL);
        })
        .catch(() => {
          console.error("Error al obtener el usuario");
        });
    } else if (notification.sender) {
      getClub(notification.sender.id)
        .then((club) => {
          setImgUrl(club?.logoUrl);
        })
        .catch(() => {
          console.error("Error al obtener el club");
        });
    } else {
      setImgUrl("/img/logo.png");
    }
  }, [notification]);
  return (
    <StyledNotificationContainer
      read={notification.read}
      onClick={() => {
        if (!notification.read) {
          updateNotification(notification.id, { read: true });
        }
        if (notification.type === notificacionTypes.reserva) {
          navigate("/admin/turnos");
        } else if ([notificacionTypes.confirmacion, notificacionTypes.rechazo].includes(notification.type)) {
          navigate("/mis-reservas");
        }
      }}
    >
      <StyledTextContainer>
        <StyledImg src={imgUrl}></StyledImg>
        <StyledDescriptionContainer>
          <StyledDescription>{notification.description}</StyledDescription>
          <StyledHora read={notification.read}>{timeAgo.format(notification.fecha.toDate())}</StyledHora>
        </StyledDescriptionContainer>
      </StyledTextContainer>
      <StyledReadContainer>
        <StyledRead read={notification.read}></StyledRead>
      </StyledReadContainer>
    </StyledNotificationContainer>
  );
};

export default NotificationCard;
