import {
  StyledSEmailSigninFooterItem,
  StyledEmailSigninInput,
  StyledEmailSigninLayout,
  StyledEmailSigninTitle,
  StyledErrorContainer,
  StyledPolicyLink,
  StyledEmailSigninForm,
} from "./EmailSigninLayout.styled";

const EmailSigninLayout = StyledEmailSigninLayout;

EmailSigninLayout.Form = StyledEmailSigninForm;

EmailSigninLayout.Title = StyledEmailSigninTitle;

EmailSigninLayout.Input = StyledEmailSigninInput;

EmailSigninLayout.FooterItem = StyledSEmailSigninFooterItem;

EmailSigninLayout.ErrorContainer = StyledErrorContainer;

EmailSigninLayout.PolicyLink = StyledPolicyLink;

export default EmailSigninLayout;
