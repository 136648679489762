import { styled } from "../../theme/styled";

export const FullPage = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const FixedPart = styled("div", {
  flex: "0 0 auto",
});

export const ScrollPart = styled("div", {
  overflowX: "hidden",
  overflowY: "auto",
  paddingTop: 20,
  paddingLeft: "16px",
  paddingRight: "16px",
});

export const ClubsGrid = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
  gridGap: "12px",
});

export const BottomPart = styled("div", {
  borderTop: "1px solid $light",
  paddingTop: 16,
  marginY: 48,
  marginX: 16,
  variants: {
    hidden: {
      true: {
        border: "none",
      },
    },
  }
});
