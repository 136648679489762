import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import Paragraph from "../Paragraph/Paragraph";
import { getCancha } from "../../firebase/canchas";
import { useUserData } from "../../utils/userData";
import { formatDate } from "../../utils/fechas";
import WhatsAppBtn from "../WhatsAppBtn/WhatsAppBtn";

const ModalEliminarTurno = ({ show, onClose, club, turno }) => {
  const [cancha, setCancha] = useState(null);
  const user = useUserData();

  useEffect(() => {
    if (turno?.clubId && turno?.canchaId) {
      getCancha(turno?.clubId, turno?.canchaId)
        .then((cancha) => setCancha(cancha))
        .catch(() => {
          console.error("Error al obtener la cancha");
        });
    }
  }, [turno?.canchaId, turno?.clubId]);

  const phones = club?.phones || [];

  const loading = !cancha || !club || !user || !turno;

  if (loading) {
    return null;
  }

  return (
    <Modal show={show} onClose={onClose} size={"sm"}>
      <Modal.Header>
        <Modal.Title>Cancelar reserva</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph>
          Para cancelar una reserva puedes comunicarte directamente con <b>{club.name}</b> por telefono:
          <b>{club.phones.join(" - ")}</b> o por Whatsapp:
        </Paragraph>
      </Modal.Body>
      <Modal.Footer align="center">
        <WhatsAppBtn
          phone={phones[0]}
          message={`Hola, soy ${user.displayName} y quisiera cancelar mi reserva del día ${formatDate(
            turno.fecha
          )} a las ${turno.hora} en la cancha ${cancha.name}.`}
          onRedirect={onClose}
        >
          {loading ? "Cargando" : "Enviar mensaje"}
        </WhatsAppBtn>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEliminarTurno;
