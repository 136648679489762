import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import AdminCalendar from "../components/AdminCalendar/AdminCalendar";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import CalendarButtons from "../components/CalendarButtons/CalendarButtons";
import CardTurnoAdmin from "../components/CardTurnoAdmin/CardTurnoAdmin";
import Container from "../components/Container/Container";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import TurnoCard from "../components/TurnoCard/TurnoCard";
import TurnoModal from "../components/TurnoModal/TurnoModal";
import { getCancha } from "../firebase/canchas";
import { getClub } from "../firebase/clubs";
import useGetTurnos from "../hooks/useGetTurnos";
import { fechaPasada } from "../utils/fechas";
import { AlertsContext } from "../utils/alerts";
import { useUserData } from "../utils/userData";
import useIsOnline from "../hooks/useIsOnline";
import NoInternet from "../components/NoInternet/NoInternet";
import useValidateClub from "../hooks/useValidateClub";
import NoResults from "../components/NoResults/NoResults";

const CanchaPage = () => {
  const today = new Date();
  today.setMinutes(today.getMinutes() - today.getTimezoneOffset());

  const user = useUserData();
  const isOnline = useIsOnline();
  const { clubId, canchaId } = useParams();
  const [club, setClub] = useState(null);
  const [cancha, setCancha] = useState(null);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(today.toISOString().split("T")[0]);
  const { users, turnos, setTurnos } = useGetTurnos(club, cancha, fechaSeleccionada);
  const loading = !cancha || !club;
  const loadingTurnos = !turnos;
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(null);
  const [show, setShow] = useState(false);
  const userIsAdmin = !!club?.admins?.[user?.uid];
  const { addAlert } = useContext(AlertsContext);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (turno) => {
    if (!userIsAdmin && fechaPasada(turno.fecha, turno.hora, club.timezone)) {
      addAlert({
        type: "toast",
        message: "Este turno ya no está disponible",
      });
      return;
    }
    if (userIsAdmin || turno.estado === "DISPONIBLE") {
      openModal(turno);
    }
  };
  const openModal = (turno) => {
    setShow(true);
    setTurnoSeleccionado(turno);
  };

  useEffect(() => {
    getCancha(clubId, canchaId)
      .then((data) => setCancha(data))
      .catch(() => {
        console.error("Error al obtener la cancha");
      });
    getClub(clubId)
      .then((data) => setClub(data))
      .catch(() => {
        console.error("Error al obtener el club");
      });
  }, [clubId, canchaId]);

  useValidateClub(club);

  const renderTurnoCard = (turno, hora) => {
    if (userIsAdmin) {
      return (
        <CardTurnoAdmin
          key={turno.hora}
          turno={turno}
          horaFin={hora.end}
          fijo={turno.fijo || turno.eraFijo}
          user={users?.[turno.user?.id]}
          onClick={() => handleShow(turno)}
          name={turno.name}
        />
      );
    } else {
      return (
        <TurnoCard
          key={turno.hora}
          estado={turno.estado}
          name={turno.user ? users?.[turno.user.id].displayName : turno.name}
          hora={turno.hora}
          horaFin={hora.end}
          onClick={() => {
            handleShow(turno);
          }}
        />
      );
    }
  };

  const renderTurnos = () => {
    const turnoCards = [];

    let fechaActual = new Date(fechaSeleccionada);
    fechaActual = new Date(fechaActual.getTime() + fechaActual.getTimezoneOffset() * 60000);
    const diaSemana = fechaActual.getDay();
    const horarioDia = cancha?.horarios?.[diaSemana];
    horarioDia?.forEach((hora) => {
      //busco o creo el turno
      let turno = turnos?.find((turno) => turno.hora === hora.start);
      if (!turno) {
        turno = {
          estado: "DISPONIBLE",
          name: "",
          hora: hora.start,
          fecha: fechaSeleccionada,
        };
      }

      //
      const turnoDisponible = turno.estado === "DISPONIBLE" && !fechaPasada(turno.fecha, turno.hora, club.timezone);
      const miTurno = Boolean(user?.uid && turno.user?.id && turno.user?.id === user?.uid);

      if (userIsAdmin || miTurno || turnoDisponible) {
        turnoCards.push(renderTurnoCard(turno, hora));
      }
    });

    if (turnoCards.length === 0) {
      return <NoResults msg={"No quedan turnos disponibles para este día"} />;
    }

    return turnoCards;
  };

  if (loading) {
    return <SmallLoader visible />;
  } else {
    return (
      <ScrollLayout>
        <ScrollLayout.FixedPart>
          <Container>
            <Breadcrumb
              pages={[
                { label: club.name, url: `/club/${club.id}`, name: "club" },
                { label: cancha.name, name: "cancha" },
              ]}
            ></Breadcrumb>
            {userIsAdmin ? (
              <AdminCalendar
                diaSeleccionado={fechaSeleccionada}
                onSelectDay={(dia) => {
                  setTurnos(null);
                  setFechaSeleccionada(dia);
                }}
              />
            ) : (
              <CalendarButtons
                diaSeleccionado={fechaSeleccionada}
                displayedDays={club.displayedDays}
                onSelectDay={(dia) => {
                  setTurnos(null);
                  setFechaSeleccionada(dia);
                }}
              ></CalendarButtons>
            )}
          </Container>
        </ScrollLayout.FixedPart>
        <ScrollLayout.ScrollPart>
          <Container>
            {loadingTurnos ? (
              <SmallLoader visible />
            ) : (
              <>
                {isOnline ? renderTurnos() : <NoInternet />}

                <TurnoModal
                  show={show}
                  handleClose={handleClose}
                  turno={turnoSeleccionado}
                  canchas={[cancha]}
                  club={club}
                ></TurnoModal>
              </>
            )}
          </Container>
        </ScrollLayout.ScrollPart>
      </ScrollLayout>
    );
  }
};

export default CanchaPage;
