import { getDoc } from "firebase/firestore";

const getReservas = async (reservas) => {
  const turnos = [];
  const promises = [];
  if (reservas) {
    for (const reservaRef of reservas) {
      promises.push(getDoc(reservaRef));
    }
    const turnosDocs = await Promise.all(promises);
    for (const turno of turnosDocs) {
      if (turno.exists()) {
        turnos.push({
          id: turno.id,
          ...turno.data(),
          canchaId: turno.ref.parent.parent.id,
          clubId: turno.ref.parent.parent.parent.parent.id,
        });
      }
    }
  }
  return turnos.sort((a, b) => {
    const fechaA = a.fecha + "T" + a.hora;
    const fechaB = b.fecha + "T" + b.hora;

    if (fechaA < fechaB) {
      return 1;
    }
    if (fechaA > fechaB) {
      return -1;
    }
    return 0;
  });
};

export { getReservas };
