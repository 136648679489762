import { styled } from "../../theme/styled";

export const StyledSelectLocationContainer = styled("div", {
  position: "fixed",
  width: "100%",
  height: "100%",
  maxWidth: "500px",
  zIndex: 100,
  opacity: 1,
  visibility: "visible",
  transition: "none 0.2s ease-in-out",
  transitionProperty: "opacity, visibility",
  variants: {
    show: {
      false: {
        opacity: 0,
        visibility: "hidden",
      },
    },
  },
});

export const StyledFondoSelect = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0,0,0,0.5)",
  zIndex: 10,
});

export const StyledSelectContainer = styled("div", {
  padding: "20px",
  position: "absolute",
  minHeight: "400px",
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  backgroundColor: "$white",
  transform: "translateY(0)",
  transition: "transform 0.2s ease-in-out",
  variants: {
    show: {
      false: {
        transform: "translateY(100%)",
      },
    },
  },
});

export const StyledCloseButton = styled("button", {
  position: "absolute",
  top: 8,
  right: 8,
  width: 36,
  height: 36,
  fontSize: 24,
  border: 0,
  padding: 0,
  color: "$dark",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  cursor: "pointer",
});

export const StyledTitleLocation = styled("span", {
  fontSize: "18px",
  fontWeight: "600",
  color: "$dark",
});

export const StyledSavedLocationsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
});

export const StyledSavedLocations = styled("div", {
  borderBottom: "1px solid $gray",
  display: "flex",
  flexDirection: "row",
  padding: "20px 0",
});

export const StyledSavedIcon = styled("div", {
  marginRight: "8px",
});

export const StyledSavedText = styled("span", {
  color: "$dark",
  fontWeight: "500",
});
