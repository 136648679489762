import { styled } from "../../theme/styled";

export const StyledPageContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: 16,
});

export const StyledMapContainer = styled("div", {
  position: "relative",
  width: "100%",
  height: "300px",
});

export const StyledInfoContainer = styled("div", {
  padding: "16px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const StyledDireccionContainer = styled("div", {
  padding: "16px 0",
  borderBottom: "1px solid $gray",
});
