import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";

import App from "./App";

if (window.location.hostname === "jugaya.ar") {
  Sentry.init({
    dsn: "https://c2b30bfb893543968059f7958261ed7a@o1381869.ingest.sentry.io/6695704",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.7,
  });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

try {
  registerServiceWorker();
} catch (e) {
  console.error("JugaYa: Service worker registration failed", e);
}

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
