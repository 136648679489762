import React, { useEffect, useState } from "react";
import LayoutTurnoAdmin from "../components/AdminTurnoLayout/AdminTurnoLayout";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import CardTurnoAdmin from "../components/CardTurnoAdmin/CardTurnoAdmin";
import Container from "../components/Container/Container";
import NoInternet from "../components/NoInternet/NoInternet";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import Title from "../components/Title/Title";
import TurnoModal from "../components/TurnoModal/TurnoModal";
import { getAdminClubs } from "../firebase/clubs";
import { onTurnosClubSnapshot } from "../firebase/turnos";
import { getUser } from "../firebase/users";
import useIsOnline from "../hooks/useIsOnline";
import { useUserData } from "../utils/userData";

const AdminTurnoPage = () => {
  const user = useUserData();
  const isOnline = useIsOnline();
  const [isLoading, setIsLoading] = useState(true);
  const [clubs, setClubs] = useState({});
  const [canchas, setCanchas] = useState({});
  const [turnos, setTurnos] = useState({});
  const [users, setUsers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(null);
  const [canchaSeleccionada, setCanchaSeleccionada] = useState(null);
  const [clubSeleccionado, setClubSeleccionado] = useState(null);

  const openModal = (turno, cancha, club) => {
    setTurnoSeleccionado(turno);
    setCanchaSeleccionada(cancha);
    setClubSeleccionado(club);
    setShowModal(true);
  };

  useEffect(() => {
    const suscriptions = [];
    (async () => {
      if (user) {
        // Obtengo arreglo de clubs
        const clubList = await getAdminClubs(user.uid);
        // Lo transformo a objeto
        const clubsObj = {};
        clubList.forEach((club) => {
          clubsObj[club.id] = club;
        });
        // lo guardo
        setClubs(clubsObj);

        // Pido los turnos/canchas de cada club
        for (const club of clubList) {
          const sub = await onTurnosClubSnapshot(club.id, "PENDIENTE", async (cancha, turnosDb) => {
            // actualizo el objeto de canchas
            setCanchas((prev) => {
              const clubCanchas = prev[club.id] || {};
              clubCanchas[cancha.id] = cancha;

              return {
                ...prev,
                [club.id]: clubCanchas,
              };
            });

            // actualizo el objeto de turnos
            setTurnos((prev) => {
              const newTurnos = { ...prev };
              if (turnosDb.length > 0) {
                newTurnos[club.id] = newTurnos[club.id] || {};
                newTurnos[club.id][cancha.id] = turnosDb;
              } else {
                if (
                  newTurnos[club.id] &&
                  Object.keys(newTurnos[club.id]).length === 1 &&
                  newTurnos[club.id][cancha.id]
                ) {
                  delete newTurnos[club.id];
                } else {
                  if (newTurnos[club.id]) {
                    delete newTurnos[club.id][cancha.id];
                  }
                }
              }
              return newTurnos;
            });

            // actualizo el objeto de usuarios
            for (const turno of turnosDb) {
              if (turno.user) {
                const turnoUser = await getUser(turno.user.id);
                setUsers((users) => ({
                  ...users,
                  [turno.user.id]: turnoUser,
                }));
              }
            }
          });
          suscriptions.push(sub);
        }
      }

      setIsLoading(false);
    })();

    return () => {
      suscriptions.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [user]);

  if (isLoading) {
    return <SmallLoader visible />;
  } else {
    return (
      <>
        <ScrollLayout>
          <ScrollLayout.FixedPart>
            <Container>
              <Breadcrumb
                pages={[
                  { label: "Administración", url: "/admin", name: "admin" },
                  { label: "Turnos", name: "turnos" },
                ]}
              ></Breadcrumb>
            </Container>
          </ScrollLayout.FixedPart>
          <ScrollLayout.ScrollPart noSpace>
            <LayoutTurnoAdmin>
              {isOnline ? (
                Object.values(clubs).map((club) => {
                  if (turnos[club.id] && Object.keys(turnos[club.id]).length > 0) {
                    return (
                      <LayoutTurnoAdmin.ClubContainer key={club.id}>
                        <LayoutTurnoAdmin.Title>
                          <Title size="lg">{club.name}</Title>
                        </LayoutTurnoAdmin.Title>
                        {Object.values(canchas?.[club.id] || {}).map((cancha) => (
                          <div key={cancha.id}>
                            {turnos[club.id][cancha.id] && <Title>{cancha.name}</Title>}

                            {turnos[club.id][cancha.id]?.map((turno) => (
                              <CardTurnoAdmin
                                key={turno.id}
                                turno={turno}
                                user={users[turno.user.id]}
                                onClick={() => openModal(turno, cancha, club)}
                                showDate
                                cancha={cancha.name}
                              />
                            ))}
                          </div>
                        ))}
                      </LayoutTurnoAdmin.ClubContainer>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <NoInternet />
              )}
              {isOnline && (!turnos || Object.keys(turnos).length === 0) && (
                <>
                  <p className="text-center">En este momento no hay turnos pendientes</p>
                </>
              )}
            </LayoutTurnoAdmin>
          </ScrollLayout.ScrollPart>
        </ScrollLayout>
        <TurnoModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          turno={turnoSeleccionado}
          canchas={[canchaSeleccionada]}
          club={clubSeleccionado}
        />
      </>
    );
  }
};

export default AdminTurnoPage;
