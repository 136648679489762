import { styled } from "../../theme/styled";
import { IoIosArrowDown } from "react-icons/io";

export const StyledSelectContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const StyledSelect = styled("select", {
  border: "none",
  borderRadius: "10em",
  padding: "14px 14px 12px 14px",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "22px",
  color: "$dark",
  shadow: 1,
  width: "100%",
  margin: "8px 0",
  appearance: "none",
  backgroud: "$white",
  "&::focus": {
    outline: "2px solid $dark",
  },
  "&:disabled": {
    backgroundColor: "$light",
    color: "$gray",
  },
});

export const StyledSelectIcon = styled(IoIosArrowDown, {
  position: "absolute",
  right: "12px",
  bottom: "24px",
  pointerEvents: "none",
  color: "$dark",
});

export const StyledSelectLabel = styled("label", {
  display: "block",
  fontSize: "16px",
  fontWeight: "bold",
  position: "relative",
});
