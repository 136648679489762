import { styled } from "../../theme/styled";
import { IoIosArrowDown } from "react-icons/io";

export const StyledButtonSelect = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const StyledButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  shadow: 1,
  borderRadius: "10em",
});

export const StyledButton = styled("div", {
  padding: "10px 10px 10px 20px",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
  cursor: "pointer",
});

export const StyledButtonSelectOptionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  height: "100%",
});

export const StyledOptions = styled(IoIosArrowDown, {
  borderLeft: "1px solid $light",
  padding: "0 14px 0 10px",
  fontSize: 16,
  width: "42px",
});

export const StyledButtonSelectOption = styled("div", {
  display: "none",
  variants: {
    show: {
      true: {
        display: "block",
        position: "absolute",
        top: "42px",
        left: 0,
        backgroundColor: "$white",
        borderRadius: "10px",
        padding: "10px",
        gap: "4px",
        shadow: 1,
      },
    },
  },
});

export const StyledButtonSelectOptionItem = styled("div", {
  cursor: "pointer",
  padding: "8px 16px",
  borderBottom: "1px solid $light",
  whiteSpace: "nowrap",
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: 700,
  "&:last-child": {
    borderBottom: "none",
  },
});
