import React from "react";
import { StyledButtonMenu, StyledButtonMenuIcon, StyledButtonMenuLabel } from "./ButtonMenu.styles";

const ButtonMenu = ({ icon, label, onClick }) => {
  const Icon = icon;
  return (
    <StyledButtonMenu onClick={onClick}>
      <StyledButtonMenuIcon>
        <Icon />
      </StyledButtonMenuIcon>
      <StyledButtonMenuLabel>{label}</StyledButtonMenuLabel>
    </StyledButtonMenu>
  );
};

export default ButtonMenu;
