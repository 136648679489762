import { AiOutlineLoading3Quarters as LoadIcon } from "react-icons/ai";
import { keyframes, styled } from "../../theme/styled";

const giro = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const StyledSmallLoader = styled(LoadIcon, {
  animationName: giro,
  animationDuration: "1s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  fontSize: "24px",
  variants: {
    visible: {
      true: {
        margin: "12px auto",
        color: "$dark",
        display: "block",
      },
      false: {
        display: "none",
      },
    },
  },
});
