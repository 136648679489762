import React from "react";
import { StyledCardEnlace, StyledIcon, StyledLink, StyledTitle, StyledTitleAndLink } from "./CardEnlace.styles";

const CardEnlace = ({title, link, onClick}) => {
  return (
    <StyledCardEnlace>
      <StyledTitleAndLink>
        <StyledTitle>{title}</StyledTitle>
        <StyledLink>{link}</StyledLink>
      </StyledTitleAndLink>
      <StyledIcon onClick={onClick}/>
    </StyledCardEnlace>
  );
};

export default CardEnlace;
