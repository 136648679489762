import { styled } from "../../theme/styled";

export const StyledClubSelector = styled("div", {
  display: "flex",
  flexShrink: 0,
  flexDirection: "row",
  alignItems: "center",
  overflowX: "auto",
  overflowY: "hidden",
  height: "100px",
  paddingLeft: 16,
});

export const StyledClubSelectorItem = styled("div", {
  height: "74px",
  width: "142px",
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  backgroundColor: "$card",
  borderRadius: "8px",
  marginRight: "12px",
  shadow: 1,
  transition: "none 0.2s ease-in-out",
  transitionProperty: "opacity, background",
  variants: {
    selected: {
      false: {
        opacity: 0.5,
        backgroundColor: "$light",
      },
    },
  },
});

export const StyledClubName = styled("span", {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "24px",
  color: "$dark",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  marginTop: "6px",
  marginLeft: "8px",
});

export const StyledDeuda = styled("span", {
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "30px",
  color: "$primary",
  marginTop: "16px",
  marginLeft: "8px",
});

export const StyledClubText = styled("span", {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  marginLeft: "8px",
});

export const StyledEstadoDeudaContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
  variants: {
    color: {
      red: {
        color: "$red",
      },
      green: {
        color: "$green",
      },
    },
  },
});

export const StyledEstadoDeuda = styled("span", {
  marginRight: "4px",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "18px",
});

export const StyledEstadoDeudaIcon = styled("div", {});
