import { styled } from "../../theme/styled";
import { FiMoreVertical } from "react-icons/fi";

export const StyledDropdownContainer = styled("div", {
    position: "absolute",
    right: 8,
    top: 8,
});

export const StyledDropdown = styled(FiMoreVertical, {
  cursor: "pointer",
  fontSize: "20px",
  color: "$dark",
});

export const StyledOptionContainer = styled("div", {
  position: "absolute",
  top: 0,
  right: 24,
  backgroundColor: "$white",
  shadow: 4,
  borderRadius: "8px",
});

export const StyledOption = styled("div", {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  padding: "10px",
  borderRadius: "8px",
  whiteSpace: "nowrap",
  cursor: "pointer",
    "&:active": {
        backgroundColor: "$lighter",
    },
});
