import React from "react";
import Tag from "../Tag/Tag";
import {
  StyledClubDescription,
  //StyledClubKm,
  StyledClubName,
  StyledContainerCard,
  StyledContainerData,
  StyledContainerImage,
  StyledImage,
} from "./ClubCard.styles";

const ClubCard = ({ club, onClick }) => {
  return (
    <StyledContainerCard onClick={onClick}>
      <StyledContainerImage>
        <StyledImage src={club?.photos?.[0]} />
      </StyledContainerImage>
      <StyledContainerData>
        <StyledClubName>{club.name}</StyledClubName>
        <StyledClubDescription>{club.description}</StyledClubDescription>
        {club.tags?.map((tag) => (
          <Tag key={tag.label} color={tag.color}>
            {tag.label}
          </Tag>
        ))}
        {/* <StyledClubKm>0.3Km</StyledClubKm> */}
      </StyledContainerData>
    </StyledContainerCard>
  );
};

export default ClubCard;
