import { styled } from "../../theme/styled";

export const StyledImageContainer = styled("div", {
  position: "relative",
  overflow: "hidden",
  backgroundColor: "black",
  height: "calc(100% + 32px)",
});

export const StyledImageIndicatorsContainer = styled("div", {
  position: "absolute",
  top: "8px",
  left: "0",
  width: "100%",
  display: "flex",
  zIndex: 10,
});

export const StyledImageIndicator = styled("div", {
  flex: 1,
  height: 4,
  backgroundColor: "$white",
  marginX: 2,
  borderRadius: 2,
  shadow: 2,
  variants: {
    active: {
      true: {
        opacity: 0.8,
      },
      false: {
        opacity: 0.4,
      },
    },
  },
});

export const StyledImage = styled("img", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "opacity 0.5s ease-in-out",
  variants: {
    visible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
});
