import { Link } from "react-router-dom";
import { styled } from "../../theme/styled";

export const StyledEmailSigninLayout = styled("div", {
  display: "block",
  height: "100%",
  width: "100%",
  overflow: "auto",
  padding: "32px 16px",
  variants: {
    align: {
      center: {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
});

export const StyledEmailSigninForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const StyledEmailSigninTitle = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const StyledEmailSigninInput = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "16px auto",
  width: "100%",
  maxWidth: 350,
});

export const StyledErrorContainer = styled("span", {
  textAlign: "center",
  display: "block",
  color: "$red",
  fontSize: 14,
  maxWidth: 350,
});

export const StyledSEmailSigninFooterItem = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "12px 0",
});

export const StyledPolicyLink = styled(Link, {
  color: "$dark",
  textAlign: "center",
  marginTop: "32px",
  marginBottom: "24px",
});
