import { styled } from "../../theme/styled";

export const StyledReservaLayout = styled("div", {
  height: "100%",
});

export const ReservasGrid = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  gridGap: "12px",
  marginBottom: "16px",
});

export const ReservaTitle = styled("div", {});
