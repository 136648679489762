import { collection, getDocs, query } from "firebase/firestore";
import { db } from ".";

const getPeriods = async (clubId) => {
  const q = query(collection(db, `clubs/${clubId}/periods`));
  const querySnapshot = await getDocs(q);
  const periods = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    periods.push({
      id: doc.id,
      ...data,
    });
  }
  periods.sort((a, b) => {
    const dateA = a.start;
    const dateB = b.start;
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  return periods;
};

export { getPeriods };
