import { StyledAdminTurnoPageContainer, StyledClubContainer, StyledSeparation, StyledTitleContainer } from "./AdminTurnoLayout.styles";

const LayoutTurnoAdmin = StyledAdminTurnoPageContainer;

LayoutTurnoAdmin.Title = StyledTitleContainer;

LayoutTurnoAdmin.Separation = StyledSeparation;

LayoutTurnoAdmin.ClubContainer = StyledClubContainer;

export default LayoutTurnoAdmin;
