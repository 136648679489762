import { FiCheck } from "react-icons/fi";
import { styled } from "../../theme/styled";

export const StyledCheckContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "16px 0",
});

export const StyledCheckLabel = styled("label", {
  userSelect: "none",
  display: "flex",
  flexDirection: "row",
  flexGrow: 0,
});

export const StyledBox = styled("div", {
  flexGrow: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "16px",
  width: "16px",
  borderRadius: "2px",
  color: "$white",

  variants: {
    checked: {
      true: {
        backgroundColor: "$primary",
        border: "1px solid $primary",
      },
      false: {
        backgroundColor: "$white",
        border: "1px solid $gray",
      },
    },
    disabled: {
      true: {
        backgroundColor: "$gray !important",
        border: "1px solid $gray !important",
      },
    },
  },
});

export const StyledBoxIcon = styled(FiCheck, {});

export const StyledText = styled("span", {
  marginLeft: "8px",
});

export const StyledCheckBox = styled("input", {
  display: "none",
});
