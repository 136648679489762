import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { currencyFormatter } from "../../utils/money";
import Button from "../Button/Button";
import SmallLoader from "../SmallLoader/SmallLoader";

import { callFunction } from "../../firebase";
import {
  StyledAiOutlineLike,
  StyledCardClubName,
  StyledCardContainer,
  StyledCardPrecio,
  StyledCardTitle,
  StyledEstadoPago,
  StyledEstadoPagoContainer,
  StyledEstadoPagoIcon,
  StyledFiDollarSign,
  StyledLeftCardContainer,
  StyledRightCardContainer,
  StyledVencimientoPago,
} from "./ClubCardFacturacion.styles";
import Container from "../Container/Container";
import { formatDate } from "../../utils/fechas";

const ClubCardFacturacion = ({ club }) => {
  const [linkLoading, setLinkLoading] = useState(false);

  const openPeriod = club.periods.find((period) => period.status === "open");
  const ultimoDiaParaPagar = formatDate(openPeriod.end);

  const generateLink = async (amount) => {
    setLinkLoading(true);
    try {
      const result = await callFunction("createPreference", {
        amount: amount,
        clubId: club.id,
      });
      if (result.data) {
        window.location.href = result.data;
      }
    } catch {
      // mostrar mensaje de error
      alert("Hubo un error al generar el link de pago, por favor intenta nuevamente.");
    } finally {
      setLinkLoading(false);
    }
  };

  if (!club) return <SmallLoader visible />;
  return (
    <Container>
      <StyledCardTitle>Resumen</StyledCardTitle>
      <StyledCardContainer>
        <StyledLeftCardContainer>
          <StyledCardClubName>{club.name}</StyledCardClubName>
          <StyledCardPrecio color={club.saldo < 0 ? "red" : "green"}>
            {currencyFormatter.format(Math.abs(club.saldo))}
          </StyledCardPrecio>
        </StyledLeftCardContainer>
        <StyledRightCardContainer>
          {club.saldo < 0 ? (
            <>
              <Button disabled={linkLoading} color="red" onClick={() => generateLink(Math.abs(club.saldo))}>
                {linkLoading ? "Cargando..." : "Pagar ahora"}
              </Button>
              <StyledEstadoPagoContainer>
                <StyledEstadoPago color={"red"}>Pago pendiente</StyledEstadoPago>
                <StyledEstadoPagoIcon>
                  <FiAlertCircle />
                </StyledEstadoPagoIcon>
              </StyledEstadoPagoContainer>
            </>
          ) : club.saldo === 0 ? (
            <>
              <StyledAiOutlineLike />
              <StyledEstadoPagoContainer>
                <StyledEstadoPago color={"green"}>Estas al dia</StyledEstadoPago>
              </StyledEstadoPagoContainer>
            </>
          ) : club.saldo > 0 ? (
            <>
              <StyledFiDollarSign />
              <StyledEstadoPagoContainer>
                <StyledEstadoPago color={"green"}>Saldo a favor</StyledEstadoPago>
              </StyledEstadoPagoContainer>
            </>
          ) : null}
        </StyledRightCardContainer>
      </StyledCardContainer>
      <StyledVencimientoPago>
        {club.saldo < 0
          ? `Tenés hasta el ${ultimoDiaParaPagar} para pagar.`
          : club.saldo === 0
          ? "Te avisaremos cuando esté disponible el próximo pago."
          : "El saldo a favor se descontará automáticamente."}
      </StyledVencimientoPago>
    </Container>
  );
};

export default ClubCardFacturacion;
