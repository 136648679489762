import { styled } from "../../theme/styled";

export const StyledNoInternetContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "32px",
});

export const StyledNoInternetButton = styled("button", {
  backgroundColor: "$primary",
  color: "$white",
  padding: "10px 20px",
  borderRadius: "16px",
  border: "none",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: "bold",
  marginTop: "12px",
});
