import React from "react";
import { StyledUserPhoto, StyledUserPhotoContainer } from "./UserPhoto.styles";

const UserPhoto = ({ user }) => {
  return (
    <StyledUserPhotoContainer>
      <StyledUserPhoto src={user?.photoURL || "/img/default-user-image.png"} alt={user?.name} />
    </StyledUserPhotoContainer>
  );
};

export default UserPhoto;
