import React from "react";
import SadIcon from "../Icons/sad";
import { StyledNoResults, StyledNoResultsMsg } from "./NoResults.styles";

const NoResults = ({ msg, style }) => {
  return (
    <StyledNoResults style={style}>
      <SadIcon />
      <StyledNoResultsMsg>{msg}</StyledNoResultsMsg>
    </StyledNoResults>
  );
};

export default NoResults;
