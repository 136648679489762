import React, { useEffect, useState } from "react";
import { getCancha } from "../../firebase/canchas";
import { getClub } from "../../firebase/clubs";
import estadoTurno from "../../utils/estadoTurno";
import Dropdown from "../Dropdown/Dropdown";
import { StyledDropdownContainer } from "../Dropdown/Dropdown.styles";
import {
  StyledCardReserva,
  StyledClub,
  StyledLogoContainer,
  StyledTextContainer,
  StyledReservaEstado,
  StyledLogoReserva,
  StyledData,
} from "./CardReserva.styles";

const formatFecha = (fecha) => {
  const [año, mes, dia] = fecha.split("-");
  return `${dia}/${mes}/${año}`;
};

const CardReserva = ({ fecha, hora, estado, eliminado, clubId, canchaId, fechaPasada, onClick }) => {
  const [club, setClub] = useState(null);
  const [cancha, setCancha] = useState(null);

  useEffect(() => {
    getClub(clubId)
      .then((club) => setClub(club))
      .catch(() => {
        console.error("Error al obtener el club");
      });
    getCancha(clubId, canchaId)
      .then((cancha) => setCancha(cancha))
      .catch(() => {
        console.error("Error al obtener la cancha");
      });
  }, [clubId, canchaId]);

  const getEstado = () => {
    if (eliminado) {
      return "Rechazado";
    } else if (fechaPasada && estado === "OCUPADO") {
      return "Jugado";
    }
    return estadoTurno[estado];
  };

  return (
    <StyledCardReserva>
      <StyledLogoContainer>
        <StyledLogoReserva src={club?.logoUrl} alt={club?.name} />
      </StyledLogoContainer>
      <StyledTextContainer>
        <StyledClub>{club?.name}</StyledClub>
        <StyledData>
          {cancha?.name} — {formatFecha(fecha)} — {hora}
        </StyledData>
        <StyledReservaEstado estado={eliminado ? "ELIMINADO" : estado}>{getEstado()}</StyledReservaEstado>
      </StyledTextContainer>
      <StyledDropdownContainer>
        {!fechaPasada && !eliminado ? (
          <Dropdown
            options={[
              {
                label: "Cancelar reserva",
                onClick: () => onClick(),
              },
            ]}
          ></Dropdown>
        ) : null}
      </StyledDropdownContainer>
    </StyledCardReserva>
  );
};

export default CardReserva;
