import { styled } from "../../theme/styled";

export const StyledPageContainer = styled("div", {
    height: "100%",
})

export const NotificationCard = styled("div", {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
    gridGap: "12px",
  });