import { styled } from "../../theme/styled";

export const StyledClickableMap = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 10,
  cursor: "pointer",
});
