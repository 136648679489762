import React from "react";
import SmallLoader from "../SmallLoader/SmallLoader";
import { FiAlertCircle } from "react-icons/fi";
import { AiOutlineLike } from "react-icons/ai";
import {
  StyledClubName,
  StyledClubSelector,
  StyledClubSelectorItem,
  StyledEstadoDeuda,
  StyledEstadoDeudaContainer,
  StyledEstadoDeudaIcon,
} from "./ClubSelector.styles";

const ClubSelector = ({ onSelect, value, clubs }) => {
  if (!clubs) return <SmallLoader visible />;
  return (
    <StyledClubSelector>
      {clubs.map((club) => {
        return (
          <StyledClubSelectorItem
            key={club.name}
            onClick={() => {
              onSelect(club.id);
            }}
            selected={value === club.id}
          >
            <StyledClubName>{club.name}</StyledClubName>
            <StyledEstadoDeudaContainer color={club?.saldo < 0 ? "red" : "green"}>
              <StyledEstadoDeuda>{club?.saldo < 0 ? "Pago pendiente" : "Estás al día"}</StyledEstadoDeuda>
              <StyledEstadoDeudaIcon>{club?.saldo < 0 ? <FiAlertCircle /> : <AiOutlineLike />}</StyledEstadoDeudaIcon>
            </StyledEstadoDeudaContainer>
          </StyledClubSelectorItem>
        );
      })}
    </StyledClubSelector>
  );
};

export default ClubSelector;
