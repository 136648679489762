import { styled } from "../../theme/styled";

export const StyledButtonMenu = styled("button", {
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  justifyContent: "flex-start",
  alignItems: "center",
  cursor: "pointer",
  border: "none",
  backgroundColor: "$white",
  borderBottom: "1px solid $light",
  padding: "20px 24px",
  transition: "background-color 0.2s ease",
  "&:first-child": {
    borderTop: "1px solid $light",
  },
  "&:active, &:focus": {
    backgroundColor: "$light",
    outline: "none",
  },
});

export const StyledButtonMenuIcon = styled("div", {
  color: "$dark",
  width: "24px",
  height: "24px",
  svg: {
    width: "24px",
    height: "24px",
  },
});

export const StyledButtonMenuLabel = styled("span", {
  display: "block",
  fontSize: "18px",
  color: "$dark",
});
