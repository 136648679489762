import pkg from "../../package.json";
const currentVersion = pkg.version.split(".").map((e) => parseInt(e, 10));

export const isAvailableUpdate = (version) => {
  const latestVersion = version?.split(".").map((e) => parseInt(e, 10));
  // detect update type
  let updateType = null;
  if (latestVersion[0] > currentVersion[0]) {
    updateType = "major";
  } else if (latestVersion[0] === currentVersion[0] && latestVersion[1] > currentVersion[1]) {
    updateType = "minor";
  }
  return updateType;
};
