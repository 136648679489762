import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  StyledImage,
  StyledImageContainer,
  StyledImageIndicator,
  StyledImageIndicatorsContainer,
} from "./ClubCarousel.styles";

import FakeComponent from "../FakeComponent/FakeComponent";

const ClubCarousel = ({ club, loading }) => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const images = useMemo(() => club?.photos || [], [club]);
  const interval = useRef(null);

  const startInterval = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      setCurrentImage((currentImage) => (currentImage + 1) % images.length);
    }, 5000);
  }, [images.length]);

  useEffect(() => {
    startInterval();
    return () => clearInterval(interval.current);
  }, [startInterval]);

  return (
    <StyledImageContainer
      onClick={() => {
        setCurrentImage((currentImage) => (currentImage + 1) % images.length);
        startInterval();
      }}
    >
      {!loading ? (
        <>
          <StyledImageIndicatorsContainer>
            {images.map((image, index) => (
              <StyledImageIndicator
                key={image}
                active={index === currentImage}
              />
            ))}
          </StyledImageIndicatorsContainer>
          {images.map((image, i) => (
            <StyledImage
              key={image}
              visible={i === currentImage}
              src={image}
              alt={club.name}
            />
          ))}
        </>
      ) : (
        <FakeComponent
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "0",
          }}
        />
      )}
    </StyledImageContainer>
  );
};

export default ClubCarousel;
