import React from "react";
import {
  StyledInputContainer,
  StyledSearchIcon,
  StyledTextInput,
  StyledLoadingIcon,
  StyledLabel,
} from "./TextInput.styles";

const TextInput = ({ name, search, loading, value, onChange, placeholder, label, disabled, type}) => {
  return (
    <StyledInputContainer>
      <StyledLabel>{label}
        <StyledTextInput
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
        />
        {search && !loading && <StyledSearchIcon />}
        {loading && <StyledLoadingIcon />}
      </StyledLabel>
    </StyledInputContainer>
  );
};

export default TextInput;
