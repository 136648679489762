import { styled } from "../../theme/styled";

export const StyledButton = styled("button", {
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  border: "none",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
  borderRadius: "10em",
  shadow: 1,
  cursor: "pointer",
  variants: {
    size: {
      xs: {
        padding: "4px 10px",
        fontSize: 14,
      },
      sm: {
        padding: "6px 14px",
      },
      md: {
        padding: "10px 20px",
      },
      lg: {
        flex: "1",
        shadow: 2,
        fontWeight: 500,
        fontSize: 16,
        borderRadius: "16px",
        padding: "20px",
        justifyContent: "center",
      },
    },
    color: {
      primary: {
        gradient: "$primary",
        color: "$white",
      },
      secondary: {
        gradient: "$light",
        color: "$dark",
      },
      white: {
        backgroundColor: "$white",
        color: "$dark",
      },
      warning: {
        gradient: "$yellow",
        color: "$white",
      },
      link: {
        backgroundColor: "transparent",
        color: "$primary",
        boxShadow: "none",
      },
      linkWhite: {
        backgroundColor: "transparent",
        color: "$disabled",
        boxShadow: "none",
        textDecoration: "underline",
      },
      red: {
        gradient: "$red",
        color: "$white",
      },
    },
    isDisabled: {
      true: {
        opacity: 0.6,
      },
    },
  },
});
