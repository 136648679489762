import React, { useCallback, useEffect, useState } from "react";
import useAuthState from "./hooks/useAuthState";
import { UserDataProvider } from "./utils/userData";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { routes } from "./utils/routes";
import Loading from "./components/Loading/Loading";
import useAccountReady from "./hooks/useAccountReady";
import NotificationsContainers from "./components/NotificationsContainers/NotificationsContainers";
import { AlertsProvider } from "./utils/alerts";
import NotFound from "./pages/NotFound";
import BottomBar from "./components/BottomBar/BottomBar";
import AppLayout from "./components/AppLayout/AppLayout";
import { fetchConfigs, saveFCMToken } from "./firebase";
import { NotificatinsProvider } from "./utils/notifications";
import { onNotificationsSnapshot, updateNotification } from "./firebase/notifications";
import useUpdateAvailable from "./hooks/useUpdateAvailable";
import UpdateMessage from "./components/UpdateMessage/UpdateMessage";
import ActionAlert from "./components/ActionAlert/ActionAlert";
import useIsOnline from "./hooks/useIsOnline";
import { FiWifiOff } from "react-icons/fi";
import { AdminClubsProvider, useGetAdminClubs } from "./hooks/useAdminClubs";

function App() {
  const isOnline = useIsOnline();
  const [user, loading] = useAuthState();
  const [alerts, setAlerts] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showDisconnected, setShowDisconnected] = useState(false);

  useEffect(() => {
    if (!isOnline) {
      setShowDisconnected(true);
    }
  }, [isOnline]);

  const availableUpdate = useUpdateAvailable();

  useEffect(() => {
    // Marcar notificacion como leia
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const notificationId = params.notificationId;
    if (notificationId) {
      updateNotification(notificationId, { read: true });
    }

    // Disable right click
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    fetchConfigs();

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    let unsuscribe;
    if (user) {
      saveFCMToken();

      // Notificaciones en tiempo real
      unsuscribe = onNotificationsSnapshot(user.uid, setNotifications);
    }
    return () => {
      if (unsuscribe) {
        unsuscribe();
      }
    };
  }, [user]);

  const removeAlert = useCallback((notification) => {
    if (notification.counter) {
      clearTimeout(notification.counter);
    }
    setAlerts((prev) => prev.filter((fNotification) => fNotification.id !== notification.id));
  }, []);

  const addAlert = useCallback(
    (notification) => {
      //ramdom id(string)
      const id = Math.random().toString(36).substring(2, 15);
      const newNotification = { ...notification, id };
      const time = notification.time !== undefined ? notification.time : 3000;

      if (time) {
        newNotification.counter = setTimeout(() => {
          newNotification.counter = null;
          removeAlert(newNotification);
        }, time);
      }
      setAlerts((prev) => [...prev, newNotification]);
    },
    [removeAlert]
  );
  useAccountReady(user, addAlert);
  const adminClubsValue = useGetAdminClubs(user);

  return (
    <AlertsProvider value={{ notifications: alerts, addAlert, removeAlert }}>
      <UserDataProvider value={user}>
        <AdminClubsProvider value={adminClubsValue}>
          <NotificatinsProvider value={notifications}>
            <Loading visible={loading} />
            <AppLayout>
              <AppLayout.AppContainer>
                <UpdateMessage version={availableUpdate} />
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  {routes
                    ?.filter((e) => !user || !e.login)
                    .map((route) => (
                      <Route key={route.path} path={route.path} element={<route.component {...route.options} />} />
                    ))}
                </Routes>
              </AppLayout.AppContainer>
              <AppLayout.NavContainer>
                <BottomBar />
              </AppLayout.NavContainer>
              <ActionAlert
                visible={!isOnline && showDisconnected}
                handleClose={() => {
                  setShowDisconnected(false);
                }}
                text="En este momento no tienes conexión a internet"
                icon={FiWifiOff}
                action={{ label: "Actualizar", onClick: () => window.location.reload() }}
              />
            </AppLayout>
            <NotificationsContainers />
          </NotificatinsProvider>
        </AdminClubsProvider>
      </UserDataProvider>
    </AlertsProvider>
  );
}

export default App;
