import {
  StyledDireccionContainer,
  StyledInfoContainer,
  StyledMapContainer,
  StyledPageContainer,
} from "./InfoLayout.styles";

const InfoLayout = StyledPageContainer;

InfoLayout.MapContainer = StyledMapContainer;

InfoLayout.InfoContainer = StyledInfoContainer;

InfoLayout.DireccionContainer = StyledDireccionContainer;

export default InfoLayout;
