import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { memo } from "react";
import { StyledClickableMap } from "./Map.styles";

const containerStyle = {
  width: "100%",
  height: "300px",
  zindex: 1,
};

function Map({ club }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDe1rvWTtL_l9lNAUfssV9Q3JmTZi9xiO0",
  });

  return isLoaded ? (
    <>
      <StyledClickableMap
        onClick={() => {
          const url = `https://www.google.com/maps/search/?api=1&query=${club.name + " " + club.address}`;
          window.open(url, "_blank");
        }}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: club?.location?.latitude || 0,
          lng: club?.location?.longitude || 0,
        }}
        options={{
          gestureHandling: "none",
          scrollwheel: false,
          clickableIcons: false,
          disableDefaultUI: true,
        }}
        zoom={17}
      >
        {club && (
          <Marker
            position={{
              lat: club?.location?.latitude,
              lng: club?.location?.longitude,
            }}
          ></Marker>
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default memo(Map);
