import { BottomPart, ClubsGrid } from "./HomeLayout.styles";
import { FixedPart, FullPage, ScrollPart } from "./HomeLayout.styles";

const HomeLayout = FullPage;

HomeLayout.FixedPart = FixedPart;

HomeLayout.ScrollPart = ScrollPart;

HomeLayout.ClubsGrid = ClubsGrid;

HomeLayout.BottomPart = BottomPart;

export default HomeLayout;
