import { styled } from "../../theme/styled";

export const StyledHorariosLayout = styled("div", {
  display: "grid",
  height: "100%",
  gridTemplateRows: "auto 1fr auto",
});

export const StyledHorariosLayoutTop = styled("div", {
  padding: "0 16px",
});

export const StyledHorariosLayoutCards = styled("div", {
  overflow: "auto",
  padding: "16px 16px 0 16px",
  scrollbarGutter: "stable",
  variants: {
    loading: {
      true: {
        overflow: "hidden",
      }
    },
  },
});

export const StyledHorariosLayoutButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "10px",
  gap: "10px",
  shadow: 1,
  overflowX: "auto",
  whiteSpace: "nowrap",
  position: "relative",
});
