import {
  NotificationCard,
  StyledPageContainer,
} from "./NotificationLayout.styles";

const NotificationLayout = StyledPageContainer;

NotificationLayout.NotificationCard = NotificationCard;

export default NotificationLayout;
