import { styled } from "../../theme/styled";
import { AiOutlineLike } from "react-icons/ai";
import { FiDollarSign } from "react-icons/fi";

export const StyledCardContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  shadow: 1,
  justifyContent: "space-between",
  borderRadius: "8px",
  marginTop: "16px",
});

export const StyledLeftCardContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const StyledRightCardContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "14px 16px 0 0",
});

export const StyledCardTitle = styled("span", {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "27px",
});

export const StyledCardClubName = styled("span", {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "27px",
  margin: "14px 0 0 14px",
});

export const StyledCardPrecio = styled("span", {
  fontWeight: "700",
  fontSize: "22px",
  lineHeight: "30px",
  margin: "9px 0 14px 14px",
  variants: {
    color: {
      red: { color: "$red" },
      green: { color: "$primary" },
    },
  },
});

export const StyledEstadoPagoContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "8px",
});

export const StyledEstadoPago = styled("span", {
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "19px",
  marginRight: "4px",
  variants: {
    color: {
      red: {
        color: "$red",
      },
      green: {
        color: "$green",
      },
    },
  },
});

export const StyledEstadoPagoIcon = styled("span", {
  color: "$red",
});

export const StyledVencimientoPago = styled("span", {
  display: "flex",
  justifyContent: "flex-end",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "16px",
  marginTop: "8px",
});

export const StyledAiOutlineLike = styled(AiOutlineLike, {
  color: "$primary",
  width: "42px",
  height: "42px",
});

export const StyledFiDollarSign = styled(FiDollarSign, {
  color: "$primary",
  width: "42px",
  height: "42px",
});
