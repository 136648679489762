import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { callFunction, db, logEvent } from ".";
import { getCanchas } from "./canchas";

const getTurnos = async (clubId, canchaId, fecha) => {
  const turnosRef = collection(db, "clubs", clubId, "canchas", canchaId, "turnos");
  const turnos = [];
  // Create a query against the collection.
  const q = query(turnosRef, where("fecha", "==", fecha), where("eliminado", "==", false));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    turnos.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return turnos;
};

const createTurno = async (clubId, canchaId, turno) => {
  logEvent("create_turno", {
    user: turno?.user?.id,
    clubId,
    canchaId,
    hora: turno?.hora,
  });
  await callFunction("createTurno", { clubId, canchaId, turno });
};

const updateTurno = (clubId, canchaId, turnoId, data) => {
  const turnoRef = doc(db, "clubs", clubId, "canchas", canchaId, "turnos", turnoId);
  return setDoc(turnoRef, data, { merge: true });
};

const onTurnosSnapshot = (clubId, canchaId, fecha, onChange) => {
  const turnosRef = collection(db, "clubs", clubId, "canchas", canchaId, "turnos");
  const q = query(turnosRef, where("fecha", "==", fecha), where("eliminado", "==", false));

  const unsubscribe = onSnapshot(q, onChange);
  return unsubscribe;
};

const onTurnosEstadoSnapshot = (clubId, canchaId, estado, onChange) => {
  const turnosRef = collection(db, "clubs", clubId, "canchas", canchaId, "turnos");
  const q = query(turnosRef, where("estado", "==", estado), where("eliminado", "==", false));

  const unsubscribe = onSnapshot(q, onChange);
  return unsubscribe;
};

const onTurnosClubSnapshot = async (clubId, estado, onChange) => {
  const canchas = await getCanchas(clubId);
  const unsubscribes = [];
  for (const cancha of canchas) {
    const unsubscribe = onTurnosEstadoSnapshot(clubId, cancha.id, estado, (snapshot) => {
      const turnos = [];

      snapshot.forEach((doc) => {
        turnos.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      onChange(cancha, turnos);
    });
    unsubscribes.push(unsubscribe);
  }

  return () => {
    for (const unsubscribe of unsubscribes) {
      unsubscribe();
    }
  };
};

export { getTurnos, createTurno, onTurnosSnapshot, onTurnosEstadoSnapshot, onTurnosClubSnapshot, updateTurno };
