const useGroupTurnosByTime = (turnos) => {
  // la lista de turnos original tiene canchaId. La lista agrupada tiene que tener un arreglo canchaIds

  // Agrupar los turnos por hora
  const turnosAgrupados = [];
  if (turnos) {
    turnos.forEach((turno) => {
      const turnoAgrupado = turnosAgrupados.find((t) => t.hora === turno.hora && t.horaFin === turno.horaFin);
      if (turnoAgrupado) {
        turnoAgrupado.canchaIds.push(turno.canchaId);
      } else {
        const { canchaId, ...turnoSinCancha } = turno;
        turnosAgrupados.push({
          ...turnoSinCancha,
          canchaIds: [canchaId],
        });
      }
    });
  }
  //la hora es una string hh:mm, ordenar de menor a mayor por abecedario

  return turnosAgrupados;
};

export default useGroupTurnosByTime;
