import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Title from "../components/Title/Title";
import Button from "../components/Button/Button";
import CardEnlace from "../components/CardEnlace/CardEnlace";
import EnlaceLayout from "../components/EnlaceLayout/EnlaceLayout";
import Container from "../components/Container/Container";
import Modal from "../components/Modal/Modal";
import TextInput from "../components/TextInput/TextInput";
import { createLink, deleteLink, onLinksSnapshot, updateLink } from "../firebase/links";
import NoResults from "../components/NoResults/NoResults";
import { useParams } from "react-router";

const EnlacePage = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [enlace, setEnlace] = useState([]);
  const [title, setTitle] = useState("");
  const [links, setLinks] = useState(null);
  const clubId = useParams().clubId;
  const [linkSelected, setLinkSelected] = useState(null);

  const urlValidation = (url) => {
    const urlRegex = new RegExp(
      "^(https?://)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))|" + // OR ip (v4) address
        "localhost" + // OR localhost
        "(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    return urlRegex.test(url);
  };

  useEffect(() => {
    const unsubscribe = onLinksSnapshot(clubId, (snapshot) => {
      const links = [];
      snapshot.forEach((doc) => {
        links.push({ id: doc.id, ...doc.data() });
      });
      setLinks(links);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clubId]);

  return (
    <EnlaceLayout>
      <Container>
        <Breadcrumb
          pages={[
            { label: "Administración", url: "/admin", name: "admin" },
            { label: "Enlaces", name: "enlaces" },
          ]}
        />
      </Container>
      <EnlaceLayout.Body>
        <EnlaceLayout.Button>
          <Button
            color="primary"
            size="md"
            onClick={() => {
              setTitle("");
              setEnlace("");
              setShowCreate(true);
            }}
          >
            Crear enlace
          </Button>
        </EnlaceLayout.Button>
        <EnlaceLayout.Title>
          <Title size="lg">Enlaces creados</Title>
        </EnlaceLayout.Title>
        <EnlaceLayout.Enlaces>
          {links && links.length > 0 ? (
            links.map((link) => (
              <CardEnlace
                key={link.id}
                title={link.title}
                link={link.link}
                onClick={() => {
                  setTitle(link.title);
                  setEnlace(link.link);
                  setLinkSelected(link.id);
                  setShowEdit(true);
                }}
              />
            ))
          ) : (
            <NoResults msg="No hay enlaces creados" />
          )}
        </EnlaceLayout.Enlaces>
      </EnlaceLayout.Body>
      <Modal show={showEdit} onClose={() => setShowEdit(false)}>
        <Modal.Title>Editar enlace</Modal.Title>
        <Modal.Body>
          <TextInput
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Título"
            placeholder="Título del enlace"
          />
          <TextInput
            name="url"
            value={enlace}
            onChange={(e) => setEnlace(e.target.value)}
            label="Enlace"
            placeholder="Escriba la URL del enlace"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            onClick={() => {
              const confirm = window.confirm("¿Está seguro que desea eliminar el enlace?");
              if (confirm) {
                deleteLink(linkSelected, clubId);
                setShowEdit(false);
              }
            }}
          >
            Eliminar
          </Button>
          <Button
            color="primary"
            onClick={() => {
              updateLink(linkSelected, enlace, title, clubId);
              setShowEdit(false);
            }}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCreate} onClose={() => setShowCreate(false)}>
        <Modal.Title>Crear enlace</Modal.Title>
        <Modal.Body>
          <TextInput
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Título"
            placeholder="Título del enlace"
          />
          <TextInput
            name="url"
            type={"url"}
            value={enlace}
            onChange={(e) => setEnlace(e.target.value)}
            label="Enlace"
            placeholder="Escriba la URL del enlace"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              const enlaceTrim = enlace.trim();
              const titleTrim = title.trim();
              if (!enlaceTrim || !titleTrim) {
                alert("Por favor, llene todos los campos");
              } else if (!urlValidation(enlaceTrim)) {
                alert("Por favor, ingrese una URL válida");
              } else {
                createLink(enlaceTrim, titleTrim, clubId);
                setShowCreate(false);
              }
            }}
          >
            Crear
          </Button>
        </Modal.Footer>
      </Modal>
    </EnlaceLayout>
  );
};

export default EnlacePage;
