import React, { useEffect, useRef, useState } from "react";
import CardReserva from "../components/CardReserva/CardReserva";
import Container from "../components/Container/Container";
import Header from "../components/Header/Header";
import ModalEliminarTurno from "../components/ModalEliminarTurno/ModalEliminarTurno";
import NoResults from "../components/NoResults/NoResults";
import ReservaLayout from "../components/ReservaLayout/ReservaLayout";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import Title from "../components/Title/Title";
import { getClub } from "../firebase/clubs";
import { getReservas } from "../firebase/reserva";
import { fechaPasada } from "../utils/fechas";
import { useUserData } from "../utils/userData";

const ReservasPage = () => {
  const user = useUserData();
  const [reservas, setReservas] = useState();
  const [reservasJugadas, setReservasJugadas] = useState();
  const [showModal, setShowModal] = useState(false);
  const [clubSeleccionado, setClubSeleccionado] = useState();
  const [reservaSeleccionada, setReservaSeleccionada] = useState();

  const reservasPedidas = useRef(false);

  const clubs = useRef({});

  useEffect(() => {
    (async () => {
      if (user && !reservasPedidas.current) {
        reservasPedidas.current = true;

        const listaReservas = await getReservas(user.reservas);

        for (const reserva of listaReservas) {
          if (!clubs.current[reserva.clubId]) {
            const club = await getClub(reserva.clubId);
            clubs.current = { ...clubs.current, [reserva.clubId]: club };
          }
        }

        setReservas(
          listaReservas.filter((reserva) => {
            return !fechaPasada(reserva.fecha, reserva.hora, clubs.current[reserva.clubId].timezone);
          })
        );

        setReservasJugadas(
          listaReservas.filter((reserva) => {
            return fechaPasada(reserva.fecha, reserva.hora, clubs.current[reserva.clubId].timezone);
          })
        );
      }
    })();
  }, [user]);
  const sinReservas = !(reservas?.length > 0 || reservasJugadas?.length > 0);
  const loading = !reservas && !reservasJugadas;

  return (
    <ReservaLayout>
      <ScrollLayout>
        <ScrollLayout.FixedPart>
          <Header title={"Mis reservas"}></Header>
        </ScrollLayout.FixedPart>
        <ScrollLayout.ScrollPart noSpace={!sinReservas}>
          <Container>
            <SmallLoader visible={loading} />

            {!loading && sinReservas && (
              <NoResults
                msg={
                  <>
                    Por el momento no tienes
                    <br /> ninguna reserva
                  </>
                }
              />
            )}

            {reservas?.length > 0 && (
              <>
                <ReservaLayout.ReservaTitle>
                  <Title size="lg">Pendientes a jugar</Title>
                </ReservaLayout.ReservaTitle>
                <ReservaLayout.ReservasGrid>
                  {reservas.map((turno) => (
                    <CardReserva
                      fecha={turno.fecha}
                      hora={turno.hora}
                      estado={turno.estado}
                      eliminado={turno.eliminado}
                      key={turno.id}
                      canchaId={turno.canchaId}
                      clubId={turno.clubId}
                      onClick={() => {
                        setClubSeleccionado(clubs.current[turno.clubId]);
                        setReservaSeleccionada(turno);
                        setShowModal(true);
                      }}
                    ></CardReserva>
                  ))}
                </ReservaLayout.ReservasGrid>
                <ModalEliminarTurno
                  show={showModal}
                  onClose={() => setShowModal(false)}
                  club={clubSeleccionado}
                  turno={reservaSeleccionada}
                ></ModalEliminarTurno>
              </>
            )}
            {reservasJugadas?.length > 0 && (
              <>
                <ReservaLayout.ReservaTitle>
                  <Title size="lg">Resevas pasadas</Title>
                </ReservaLayout.ReservaTitle>
                <ReservaLayout.ReservasGrid>
                  {reservasJugadas.map((turno) => (
                    <CardReserva
                      fecha={turno.fecha}
                      hora={turno.hora}
                      estado={turno.estado}
                      eliminado={turno.eliminado}
                      key={turno.id}
                      canchaId={turno.canchaId}
                      clubId={turno.clubId}
                      fechaPasada
                    ></CardReserva>
                  ))}
                </ReservaLayout.ReservasGrid>
              </>
            )}
          </Container>
        </ScrollLayout.ScrollPart>
      </ScrollLayout>
    </ReservaLayout>
  );
};

export default ReservasPage;
