import * as Sentry from "@sentry/react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import useStaticHandler from "./useStaticHandler";

const useAuthState = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const setSentryUser = useStaticHandler((user) => {
    if(user){
      Sentry.setUser({
        id: user.uid,
        email: user.email,
        username: user.displayName,
      });
    } else {
      Sentry.setUser(null);
    }
  })

  const updateUserData = useStaticHandler((externalUser) => {
    const userRef = doc(db, "users", externalUser.uid);
    return onSnapshot(userRef, async (userSnap) => {
      if (userSnap.exists()) {
        const userData = userSnap.data();
        // if user was deleted
        if (!userData.email) {
          setUser(null);
          setLoading(false);
          return;
        }
        setUser({ uid: externalUser.uid, ...userData });
        setLoading(false);
      }
    });
  });

  useEffect(() => {
    let unsubscribe;

    let unsubscribeAuth = onAuthStateChanged(auth, async (externalUser) => {
      if (unsubscribe) {
        unsubscribe();
      }
      // Si esta iniciando sesion
      if (externalUser) {
        try {
          unsubscribe = updateUserData(externalUser);
          setSentryUser(externalUser);
        } catch (e) {
          console.error("Error getting user data", e);
        }
      } else {
        // Si esta cerrando sesion
        setSentryUser(null);
        setUser(null);
        setLoading(false);
      }
    });

    return () => {
      if (unsubscribeAuth) {
        unsubscribeAuth();
      }
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setSentryUser, updateUserData]);

  return [user, loading];
};

export default useAuthState;
