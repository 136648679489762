// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider, getAuth, getRedirectResult, signInWithRedirect } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator as fnConnect } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { getAnalytics, logEvent as logEventFb } from "firebase/analytics";
import { getMessaging, isSupported as isMessagingSupported, getToken } from "firebase/messaging";
import pkg from "../../package.json";
import { isIOSApp } from "../utils/ios";
import { afterLoginHandler } from "../utils/login";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCrcklURL0TjTwNosLj66InuVkcoqPAriA",
  authDomain: isIOSApp() ? "jugaya.ar" : "turnos-app-4431b.firebaseapp.com",
  projectId: "turnos-app-4431b",
  storageBucket: "turnos-app-4431b.appspot.com",
  messagingSenderId: "321240866197",
  appId: "1:321240866197:web:c55e7bc215b52a11122a9c",
  measurementId: "G-DZLBW6QC1P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const analytics = getAnalytics(app);

const logEvent = (event, params) =>
  logEventFb(analytics, event, {
    ...params,
    app_version: pkg.version,
  });

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
const db = getFirestore(app);
const functions = getFunctions(app);
const remoteConfig = getRemoteConfig(app);
const getConfig = (value) => getValue(remoteConfig, value);

const fetchConfigs = () => fetchAndActivate(remoteConfig);

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", "4003");
  fnConnect(functions, "localhost", "4002");
}

let messaging;

(async () => {
  if (await isMessagingSupported()) {
    messaging = getMessaging(app);
  } else {
    console.warn("JugaYa: FCM is not supported in this browser");
  }
})();

const callFunction = (name, data) => {
  return httpsCallable(functions, name)(data);
};

const getFCMToken = async (retry = 5) => {
  try {
    if (window.deviceToken) {
      return window.deviceToken;
    }
    return await getToken(messaging, {
      vapidKey: "BEPvFjElnSgNVz5ncLBm3JQAu3DGwwiYbyX_sQk7X02WdMB_HDh_H8EsR7gz7FOBJWsWtcVcaslzpC8bzdApikk",
    });
  } catch (e) {
    if (retry > 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return getFCMToken(retry - 1);
    }
    throw e;
  }
};

const saveFCMToken = async () => {
  try {
    const token = await getFCMToken();
    callFunction("saveUserToken", { token });
  } catch (e) {
    console.error(e);
  }
};

const removeFCMToken = async () => {
  try {
    const token = await getFCMToken();
    callFunction("removeUserToken", { token });
  } catch (e) {
    console.error(e);
  }
};

if (isIOSApp()) {
  getRedirectResult(auth)
    .then((result) => {
      const comesFromRedirect = localStorage.getItem("authRedirect");
      if (comesFromRedirect && !result) {
        localStorage.removeItem("authRedirect");
        signInWithRedirect(auth, googleProvider);
      }
      if (result) {
        afterLoginHandler(result);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export {
  app,
  auth,
  googleProvider,
  appleProvider,
  db,
  getConfig,
  fetchConfigs,
  logEvent,
  callFunction,
  saveFCMToken,
  removeFCMToken,
};
