import React, { useContext, useEffect, useState } from "react";
import useAuthenticated from "../hooks/useAuthenticated";
import { logout, removeAccount } from "../utils/login";
import { useUserData } from "../utils/userData";
import UserPhoto from "../components/UserPhoto/UserPhoto";
import { FiEdit3, FiCheck } from "react-icons/fi";
import Header from "../components/Header/Header";
import UserName from "../components/UserName/UserName";
import TextInput from "../components/TextInput/TextInput";
import Button from "../components/Button/Button";
import AccountLayout from "../components/AccountLayout/AccountLayout";
import { updateUser } from "../firebase/users";
import { AlertsContext } from "../utils/alerts";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import CheckBox from "../components/CheckBox/CheckBox";
import Title from "../components/Title/Title";
import { userNotifTypes } from "../utils/userNotifTypes";
import Paragraph from "../components/Paragraph/Paragraph";
import useIsOnline from "../hooks/useIsOnline";
import useAccountVerified from "../hooks/useAccountVerified";
import Alert from "../components/Alert/Alert";

const AccountPage = () => {
  const { addAlert } = useContext(AlertsContext);
  const accountVerified = useAccountVerified();

  useAuthenticated();
  const user = useUserData();
  const isOnline = useIsOnline();
  const [displayName, setName] = useState(user?.displayName);
  const [phone, setPhone] = useState(user?.phone);
  const [editar, setEditar] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (!user?.displayName || !user?.phone) {
      setEditar(true);
    }
  }, [user]);

  const iconSelect = () => {
    if (editar === false) {
      return FiEdit3;
    } else {
      return FiCheck;
    }
  };

  const textSelect = () => {
    if (editar === false) {
      return "Editar";
    } else {
      return "Guardar";
    }
  };

  const action = async () => {
    if (editar === false) {
      setEditar(true);
    } else {
      if (displayName !== "" && phone !== "") {
        await updateUser(user?.uid, {
          displayName,
          phone,
        });
        addAlert({
          type: "toast",
          message: "Datos actualizados correctamente",
          time: 3000,
        });
        setEditar(false);
      } else {
        addAlert({
          type: "toast",
          message: "El campo de Nombre y Teléfono no pueden estar vacios",
          time: 3000,
        });
      }
    }
  };

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      await logout();
    } catch (e) {
      console.error(e);
    } finally {
      setLoggingOut(false);
    }
  };

  return (
    <>
      <ScrollLayout>
        <ScrollLayout.FixedPart>
          <Header
            title="Mi cuenta"
            actions={[{ icon: iconSelect(), onClick: action, name: "edit", visible: isOnline, text: textSelect() }]}
          ></Header>
        </ScrollLayout.FixedPart>
        <ScrollLayout.ScrollPart noSpace>
          <AccountLayout>
            <UserPhoto user={user}></UserPhoto>
            <AccountLayout.NameContainer>
              {!editar ? (
                <UserName>{displayName}</UserName>
              ) : (
                <TextInput
                  disabled={!editar || !isOnline}
                  name="name"
                  value={displayName || ""}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nombre completo"
                ></TextInput>
              )}
            </AccountLayout.NameContainer>
            {!accountVerified && (
              <Alert color="warn">
                Antes de continuar debe verificar su cuenta ingresando al enlace que le enviamos por email.
              </Alert>
            )}
            <AccountLayout.InputContainer>
              <TextInput label={"Email"} disabled={true} value={user?.email || ""}></TextInput>
              <TextInput
                label={"Teléfono"}
                disabled={!editar || !isOnline}
                name="phone"
                value={phone || ""}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Solo numeros, sin 0 ni 15"
              ></TextInput>
            </AccountLayout.InputContainer>

            <AccountLayout.NotifTitleContainer>
              <Title size="lg">Notificaciones</Title>
              <Paragraph>Me gustaría recibir notificaciones en mis dispositivos acerca de:</Paragraph>
            </AccountLayout.NotifTitleContainer>

            <AccountLayout.NotifContainer>
              {Object.keys(userNotifTypes).map((notifType) => {
                const checked = [undefined, true].includes(user?.notifications?.[notifType]);
                return (
                  <CheckBox
                    disabled={!isOnline}
                    key={notifType}
                    value={checked}
                    onChange={() =>
                      updateUser(user.uid, {
                        notifications: { ...user.notifications, [notifType]: !checked },
                      })
                    }
                  >
                    {userNotifTypes[notifType]}
                  </CheckBox>
                );
              })}
            </AccountLayout.NotifContainer>

            <AccountLayout.LogoutContainer>
              <Button color="secondary" onClick={handleLogout} disabled={loggingOut}>
                {!loggingOut ? "Cerrar sesión" : "Cerrando sesión..."}
              </Button>
            </AccountLayout.LogoutContainer>
            <AccountLayout.PolicyLink to="/policy">Politicas de privacidad</AccountLayout.PolicyLink>
            <AccountLayout.RemoveAccountLink
              onClick={async () => {
                try {
                  await removeAccount();
                } catch (e) {
                  let message = "Ocurrió un error al eliminar la cuenta, por favor intenta de nuevo.";

                  switch (e.code) {
                    case "auth/wrong-password":
                      message = "La contraseña ingresada es incorrecta, por favor intenta de nuevo.";
                      break;
                    default:
                      break;
                  }

                  addAlert({
                    type: "toast",
                    message,
                    time: 3000,
                  });
                }
              }}
            >
              Eliminar mi cuenta
            </AccountLayout.RemoveAccountLink>
          </AccountLayout>
        </ScrollLayout.ScrollPart>
      </ScrollLayout>
    </>
  );
};

export default AccountPage;
