import React from "react";
import Button from "../Button/Button";
import { StyledMsgUnirse, StyledText } from "./MsgUnirse.styles";

const MsgUnirse = () => {
  return (
    <StyledMsgUnirse>
      <StyledText>¿Tenés un complejo deportivo?</StyledText>
      <Button onClick={() => window.open("https://clubes.jugaya.ar/", "_blank")} size="sm">
        Unite a JugaYa
      </Button>
    </StyledMsgUnirse>
  );
};

export default MsgUnirse;
