import { doc, getDoc } from "firebase/firestore";
import { db } from ".";

const selectedLanguage = "es";

const getCities = async () => {
  const citiesRef = doc(db, "config/cities");
  const citiesDoc = await getDoc(citiesRef);
  const cities = citiesDoc.data();
  cities.list = cities?.list.sort((a, b) => {
    const cityA = cities[selectedLanguage]?.[a]?.toLowerCase();
    const cityB = cities[selectedLanguage]?.[b]?.toLowerCase();
    if (cityA < cityB) {
      return -1;
    }
    if (cityA > cityB) {
      return 1;
    }
    return 0;
  });
  return cities;
};

export { getCities };
