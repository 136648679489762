import { useEffect, useState } from "react";
import { onConfigSnapshot } from "../firebase/config";
import { isAvailableUpdate } from "../utils/versions";

const useUpdateAvailable = () => {
  const [updateAvailable, setUpdateAvailable] = useState(null);

  useEffect(() => {
    let unsubscribe;
    const refreshDone = sessionStorage.getItem("refreshDone", "true") === "true";
    unsubscribe = onConfigSnapshot("version", (snapshot) => {
      if (snapshot.exists()) {
        const { latestVersion: versionStr } = snapshot.data();

        let updateType = isAvailableUpdate(versionStr);

        if (updateType) {
          if (!refreshDone) {
            sessionStorage.setItem("refreshDone", "true");
            window.location.reload();
          } else {
            setUpdateAvailable(updateType);
          }
        }
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return updateAvailable;
};

export default useUpdateAvailable;
