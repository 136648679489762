import React from "react";
import { StyledContainerText, StyledContainerTooltip, StyledTextTooltip, StyledTooltip } from "./Tooltip.styles";
import { FiInfo } from "react-icons/fi";

const Tooltip = ({ text }) => {
  return (
    <StyledContainerTooltip>
      <StyledTooltip>
        <FiInfo />
      </StyledTooltip>
      <StyledContainerText>
        <StyledTextTooltip>{text}</StyledTextTooltip>
      </StyledContainerText>
    </StyledContainerTooltip>
  );
};

export default Tooltip;
