import React, { useState } from "react";
import {
  StyledAccordion,
  StyledAccordionContainer,
  StyledAccordionIcon,
  StyledAccordionText,
  StyledPlayerContainer,
} from "./Accordion.styles";

const Accordion = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <StyledAccordionContainer>
      {title && (
        <StyledAccordion
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <StyledAccordionText>{title}</StyledAccordionText>
          <StyledAccordionIcon active={isOpen}></StyledAccordionIcon>
        </StyledAccordion>
      )}
      <StyledPlayerContainer active={isOpen}>{children}</StyledPlayerContainer>
    </StyledAccordionContainer>
  );
};

export default Accordion;
