import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUserData } from "../utils/userData";

const useAuthenticated = () => {
  const user = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);
};

export default useAuthenticated;
