import React from "react";
import { MdOutlineClose, MdRoom } from "react-icons/md";
import {
  StyledCloseButton,
  StyledFondoSelect,
  StyledSavedIcon,
  StyledSavedLocations,
  StyledSavedLocationsContainer,
  StyledSavedText,
  StyledSelectContainer,
  StyledSelectLocationContainer,
  StyledTitleLocation,
} from "./SelectLocation.styles";

const selectedLanguage = "es";

const SelectLocation = ({ show, onClose, cities, onSelect }) => {
  return (
    <StyledSelectLocationContainer show={!!show}>
      <StyledFondoSelect onClick={onClose}></StyledFondoSelect>
      <StyledSelectContainer show={!!show}>
        <StyledCloseButton onClick={onClose}>
          <MdOutlineClose />
        </StyledCloseButton>
        <StyledTitleLocation>¿Dónde te encuentras?</StyledTitleLocation>
        <StyledSavedLocationsContainer>
          {cities.list?.map((cityId) => (
            <StyledSavedLocations
              key={cityId}
              onClick={() => {
                onSelect(cityId);
                onClose();
              }}
            >
              <StyledSavedIcon>
                <MdRoom />
              </StyledSavedIcon>
              <StyledSavedText>{cities[selectedLanguage]?.[cityId]}</StyledSavedText>
            </StyledSavedLocations>
          ))}
        </StyledSavedLocationsContainer>
      </StyledSelectContainer>
    </StyledSelectLocationContainer>
  );
};

export default SelectLocation;
