import { styled } from "../../theme/styled";

export const StyledToast = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  color: "white",
  borderRadius: "8px",
  padding: "16px 8px",
  position: "fixed",
  left: "50%",
  bottom: "150px",
  transform: "translate(-50%, 50%)",
  maxWidth: "300px",
  width: "90%",
  textAlign: "center",
  zIndex: 1000,
});
