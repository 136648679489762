import { styled } from "../../theme/styled";
import { FiChevronDown } from "react-icons/fi";

export const StyledAccordionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  marginBottom: 16,
});

export const StyledAccordion = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledAccordionText = styled("span", {
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "22px",
});

export const StyledAccordionIcon = styled(FiChevronDown, {
  fontSize: 24,
  transition: "transform 0.3s ease",
  variants: {
    active: {
      true: {
        transform: "rotate(180deg)",
      },
    },
  },
});

export const StyledPlayerContainer = styled("div", {
  flexDirection: "column",
  display: "none",
  variants: {
    active: {
      true: {
        display: "flex",
      },
    },
  },
});