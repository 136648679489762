import { StyledHorariosLayout, StyledHorariosLayoutButtons, StyledHorariosLayoutCards, StyledHorariosLayoutTop } from "./HorariosLayout.styles";

const HorariosLayout = StyledHorariosLayout;

HorariosLayout.Top = StyledHorariosLayoutTop;

HorariosLayout.Cards = StyledHorariosLayoutCards;

HorariosLayout.Buttons = StyledHorariosLayoutButtons;

export default HorariosLayout;
