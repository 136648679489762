import React from "react";
import { StyledPlayer, StyledPlayerPoints, StyledPlayerName } from "./CardPlayer.styles";

const CardPlayer = ({name, points, position}) => {
  return (
    <StyledPlayer>
      <StyledPlayerName>{position} - {name}</StyledPlayerName>
      <StyledPlayerPoints>{`${points} Ptos.`}</StyledPlayerPoints>
    </StyledPlayer>
  );
};

export default CardPlayer;
