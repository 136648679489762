import { FiEdit3 } from "react-icons/fi";
import { styled } from "../../theme/styled";

export const StyledCardEnlace = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr auto",
  padding: "12px",
  shadow: 1,
  borderRadius: 16,
});

export const StyledTitleAndLink = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  flex: 1,
  overflow: "hidden",
});

export const StyledTitle = styled("span", {
  fontSize: 20,
  fontWeight: 700,
  color: "$dark",
});

export const StyledLink = styled("span", {
  fontSize: 16,
  color: "$dark",
  maxWidth: "90%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  height: "20px",
});

export const StyledIcon = styled(FiEdit3, {
  fontSize: 24,
  color: "$dark",
  cursor: "pointer",
});
