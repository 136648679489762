import React, { useRef, useState } from "react";
import CardAdmin from "../components/CardAdmin/CardAdmin";
import { FiCalendar, FiFileText } from "react-icons/fi";
import { IoIosLink } from "react-icons/io";
import { MdAttachMoney } from "react-icons/md";
import { useNavigate } from "react-router";
import Container from "../components/Container/Container";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import HomeLayout from "../components/HomeLayout/HomeLayout";
import Header from "../components/Header/Header";
import useAdminClubs from "../hooks/useAdminClubs";

const AdminPage = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const promiseResolve = useRef(null);
  const promiseReject = useRef(null);

  const { adminClubs } = useAdminClubs();
  const clubs = adminClubs.filter((club) => club.visible === true);

  const selectClub = () =>
    new Promise((resolve, reject) => {
      promiseResolve.current = resolve;
      promiseReject.current = reject;
      setShow(true);
    });

  const onMiClubsClick = async () => {
    if (clubs.length === 1) {
      navigate(`/club/${clubs[0].id}`);
    } else {
      const selectedClub = await selectClub();
      navigate(`/club/${selectedClub.id}`);
    }
  };

  const onMiClubsEnlaceClick = async () => {
    if (clubs.length === 1) {
      navigate(`/admin/enlace/${clubs[0].id}`);
    } else {
      const selectedClub = await selectClub();
      navigate(`/admin/enlace/${selectedClub.id}`);
    }
  };

  const handleClose = () => {
    promiseReject.current();
    setShow(false);
  };

  const loading = clubs.length < 1;

  return (
    <>
      <Header title={"Administración"}></Header>
      <Container>
        <CardAdmin
          icon={<FiCalendar />}
          title={clubs?.length > 1 ? "Mis complejos" : "Mi complejo"}
          subtitle="Ver canchas y calendario de turnos"
          color="red"
          loading={loading}
          onClick={() => onMiClubsClick()}
        ></CardAdmin>
        <CardAdmin
          icon={<FiFileText />}
          title="Turnos"
          subtitle="Ver pendientes - Confirmar turnos"
          color="blue"
          loading={loading}
          onClick={() => navigate(`/admin/turnos`)}
        ></CardAdmin>
        <CardAdmin
          icon={<IoIosLink />}
          title="Enlaces"
          subtitle="Crear y modificar enlaces"
          color="yellow"
          loading={loading}
          onClick={() => onMiClubsEnlaceClick()}
        ></CardAdmin>
        <CardAdmin
          icon={<MdAttachMoney />}
          title="Facturacion"
          subtitle="Estadisticas - Proximos pagos"
          color="green"
          loading={loading}
          onClick={() => navigate(`/admin/facturacion`)}
        ></CardAdmin>
        <Modal show={show} onClose={handleClose}>
          <Modal.Title>Selecciona un club</Modal.Title>
          <Modal.Body>
            <HomeLayout.ClubsGrid>
              {clubs?.map((club) => (
                <Button key={club.id} color="white" size="lg" onClick={() => promiseResolve.current(club)}>
                  {club.name}
                </Button>
              ))}
            </HomeLayout.ClubsGrid>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default AdminPage;
