import React from "react";
import { StyledButton } from "./Button.styles";

const Button = ({
  children,
  size = "md",
  color = "primary",
  onClick,
  disabled,
}) => {
  return (
    <StyledButton
      size={size}
      color={color}
      onClick={onClick}
      isDisabled={disabled}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
