import React from "react";
import { BiSupport } from "react-icons/bi";
import { FiBell, FiBookmark, FiCalendar, FiCheckCircle, FiHome, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router";
import ButtonMenu from "../components/ButtonMenu/ButtonMenu";
import Header from "../components/Header/Header";
import MenuLayout from "../components/MenuLayout/MenuLayout";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import { useUserData } from "../utils/userData";
import useAdminClubs from "../hooks/useAdminClubs";

const MenuPage = () => {
  const navigate = useNavigate();
  const user = useUserData();
  const isLogged = !!user;
  const { isAdmin } = useAdminClubs();

  return (
    <ScrollLayout>
      <ScrollLayout.FixedPart>
        <Header title={"Menú"}></Header>
      </ScrollLayout.FixedPart>
      <ScrollLayout.ScrollPart>
        <MenuLayout>
          {isLogged ? (
            <>
              <ButtonMenu
                icon={FiHome}
                label={"Inicio"}
                onClick={() => {
                  navigate("/");
                }}
              />
              <ButtonMenu
                icon={FiBookmark}
                label={"Reservas"}
                onClick={() => {
                  navigate("/mis-reservas");
                }}
              />
              {isAdmin && (
                <ButtonMenu
                  icon={FiCalendar}
                  label={"Administracion"}
                  onClick={() => {
                    navigate("/admin");
                  }}
                />
              )}
              <ButtonMenu
                icon={FiBell}
                label={"Notificaciones"}
                onClick={() => {
                  navigate("/notif");
                }}
              />
              <ButtonMenu
                icon={FiUser}
                label={"Mi cuenta"}
                onClick={() => {
                  navigate("/account");
                }}
              />
              <ButtonMenu
                icon={FiCheckCircle}
                label={"Registrá tu club"}
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSd7mT8G_GzO4kAgqsw-eSEqDPT8Z-BtugxoyboJX7tJ5yuawA/viewform?fbzx=4968960267511298570",
                    "_blank"
                  );
                }}
              />
              <ButtonMenu
                icon={BiSupport}
                label={"Ayuda"}
                onClick={() => {
                  window.open("https://wa.me/5493585754749", "_blank");
                }}
              />
            </>
          ) : (
            <>
              <ButtonMenu
                icon={FiHome}
                label={"Inicio"}
                onClick={() => {
                  navigate("/");
                }}
              />
              <ButtonMenu
                icon={FiCheckCircle}
                label={"Registrá tu club"}
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSd7mT8G_GzO4kAgqsw-eSEqDPT8Z-BtugxoyboJX7tJ5yuawA/viewform?fbzx=4968960267511298570",
                    "_blank"
                  );
                }}
              />
              <ButtonMenu
                icon={BiSupport}
                label={"Ayuda"}
                onClick={() => {
                  window.open("https://wa.me/5493585754749", "_blank");
                }}
              />
            </>
          )}
        </MenuLayout>
      </ScrollLayout.ScrollPart>
    </ScrollLayout>
  );
};

export default MenuPage;
