import { serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import { createTurno, updateTurno } from "../../firebase/turnos";
import { getUserRef } from "../../firebase/users";
import useConfirm from "../../hooks/useConfirm";
import showError from "../../utils/error";
import { useUserData } from "../../utils/userData";
import Button from "../Button/Button";
import CardTurnoAdmin from "../CardTurnoAdmin/CardTurnoAdmin";
import CheckBox from "../CheckBox/CheckBox";
import Loading from "../Loading/Loading";
import Modal from "../Modal/Modal";
import SmallLoader from "../SmallLoader/SmallLoader";
import Tooltip from "../Tooltip/Tooltip";
import { StyledTurnoFijoLayout } from "../TurnoFijoLayout/TurnoFijoLayout.styles";
import Select from "../Select/Select";
import Paragraph from "../Paragraph/Paragraph";
import TextInput from "../TextInput/TextInput";
import { formatLongDate } from "../../utils/fechas";
import ButtonSelect from "../ButtonSelect/ButtonSelect";

const titlePrefix = {
  PENDIENTE: "Confirmar",
  DISPONIBLE: "Agendar",
  OCUPADO: "Modificar",
  DESACTIVADO: "Reactivar",
};

const actions = {
  PENDIENTE: {
    main: { name: "confirm", label: "Confirmar" },
    secondary: [{ name: "reject", label: "Rechazar" }],
  },
  DISPONIBLE: {
    main: { name: "create", label: "Agendar" },
    secondary: [
      { name: "deactivate", label: "Desactivar" },
      { name: "deactivateclass", label: "Desactivar: Clases" },
      { name: "deactivatecampeonato", label: "Desactivar: Torneo" },
    ],
  },
  OCUPADO: {
    main: { name: "update", label: "Guardar" },
    secondary: [
      { name: "reject", label: "Eliminar" },
      { name: "rejectonlytoday", label: "Eliminar solo esta vez", fijo: true },
    ],
  },
  DESACTIVADO: {
    main: { name: "update", label: "Guardar" },
    secondary: [
      { name: "reject", label: "Reactivar" },
      { name: "rejectonlytoday", label: "Reactivar solo esta vez", fijo: true },
    ],
  },
};

const buttonFilter = (option, turno) => {
  if (option.fijo && !turno.fijo) {
    return false;
  }
  return true;
};

const ModalAdmin = ({ handleClose, turno, canchas, club, user }) => {
  const [cancha, setCancha] = useState(canchas[0]);
  const [data, setData] = useState({ fijo: !!turno.fijo, name: turno.name });
  const userLoading = ["PENDIENTE", "OCUPADO"].includes(turno.estado) && !turno.name && !user;
  const { confirm } = useConfirm();
  const currentUser = useUserData();
  const action = actions[turno.estado];
  const secondaryActions = action.secondary.filter((option) => buttonFilter(option, turno));
  const [actionLoading, setActionLoading] = useState(false);

  const isFormValid = () => {
    if (turno.estado === "DESACTIVADO") {
      return true;
    } else {
      return data.name?.trim() || user;
    }
  };

  const actionClick = async (action) => {
    setActionLoading(true);

    const isDesactivado = turno.estado === "DESACTIVADO";

    if (action.name === "confirm") {
      await updateTurno(club.id, cancha.id, turno.id, {
        estado: "OCUPADO",
        fijo: !!data.fijo,
      });
    } else if (action.name === "reject" || action.name === "rejectanddeactivate") {
      const res = confirm(
        `¿Está seguro que desea ${
          isDesactivado ? "reactivar" : "eliminar"
        } el turno permanentemente? Este turno ya no se copiará a la semana siguiente.`
      );
      if (res) {
        const userRef = getUserRef(currentUser.uid);
        await updateTurno(club.id, cancha.id, turno.id, {
          eliminado: true,
          eliminadoFecha: serverTimestamp(),
          eliminadoPor: userRef,
        });
      }
    } else if (action.name === "create") {
      try {
        await createTurno(club.id, cancha.id, {
          estado: "OCUPADO",
          fecha: turno.fecha,
          hora: turno.hora,
          fijo: !!data.fijo,
          name: data.name,
        });
      } catch (e) {
        showError(e);
      }
    } else if (action.name === "update") {
      const turnoData = {
        fijo: !!data.fijo,
      };
      if (!user && data.name) {
        turnoData.name = data.name;
      }
      await updateTurno(club.id, cancha.id, turno.id, turnoData);
    } else if (action.name === "rejectonlytoday") {
      const res = confirm(
        `Si ${
          isDesactivado ? "reactiva" : "elimina"
        } el turno solo por hoy, este se copiará automáticamente a la semana siguiente. ¿Está seguro de que desea ${
          isDesactivado ? "reactivar" : "eliminar"
        } el turno?`
      );
      if (res) {
        const userRef = getUserRef(currentUser.uid);
        const fechaActual = new Date(turno.fecha);
        const fechaNueva = new Date(fechaActual.setDate(fechaActual.getDate() + 7));
        const fechaNuevaFormat = fechaNueva.toISOString().split("T")[0];
        try {
          await createTurno(club.id, cancha.id, {
            estado: turno.estado,
            fecha: fechaNuevaFormat,
            hora: turno.hora,
            fijo: !!turno.fijo,
            ...(turno.name && { name: turno.name }),
            ...(turno.user && { userId: turno.user.id }),
          });
          await updateTurno(club.id, cancha.id, turno.id, {
            eliminado: true,
            eliminadoFecha: serverTimestamp(),
            eliminadoPor: userRef,
          });
        } catch (e) {
          showError(e);
        }
      }
    }

    if (action.name.endsWith("deactivate")) {
      try {
        await createTurno(club.id, cancha.id, {
          estado: "DESACTIVADO",
          fecha: turno.fecha,
          hora: turno.hora,
          fijo: !!data.fijo,
        });
      } catch (e) {
        showError(e);
      }
    } else if (action.name === "deactivateclass") {
      try {
        await createTurno(club.id, cancha.id, {
          estado: "DESACTIVADO",
          fecha: turno.fecha,
          hora: turno.hora,
          name: "Clases",
          fijo: !!data.fijo,
        });
      } catch (e) {
        showError(e);
      }
    } else if (action.name === "deactivatecampeonato") {
      try {
        await createTurno(club.id, cancha.id, {
          estado: "DESACTIVADO",
          fecha: turno.fecha,
          hora: turno.hora,
          name: "Torneo",
          fijo: !!data.fijo,
        });
      } catch (e) {
        showError(e);
      }
    }
    handleClose();
    setActionLoading(false);
  };

  return (
    <>
      <Loading visible={actionLoading} modal />
      <Modal.Header>
        <Modal.Title>{titlePrefix[turno.estado]} turno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userLoading ? (
          <SmallLoader visible />
        ) : turno.user || turno.estado === "DESACTIVADO" ? (
          <CardTurnoAdmin
            size="sm"
            turno={turno}
            user={user}
            name={data.name}
            fijo={data.fijo}
            cancha={cancha.name}
          ></CardTurnoAdmin>
        ) : (
          <>
            {turno.estado === "OCUPADO" && !turno.user ? null : (
              <Paragraph>
                Se va a agendar el turno de las <b>{turno?.hora}</b> del día <b>{formatLongDate(turno.fecha)}</b>.
              </Paragraph>
            )}
            <Select
              options={canchas.map((c) => ({ value: c.id, label: c.name }))}
              value={cancha.id}
              onChange={(e) => setCancha(canchas.find((c) => c.id === e.target.value))}
              disabled={actionLoading || canchas.length === 1}
              label={"En la cancha:"}
            />
            <TextInput
              value={data.name || ""}
              onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
              label="A nombre de:"
              placeholder="Escriba el nombre"
            />
          </>
        )}
        <StyledTurnoFijoLayout>
          <CheckBox
            disabled={turno?.eraFijo}
            value={turno?.eraFijo ? turno.eraFijo : data.fijo || false}
            onChange={(value) => setData((prev) => ({ ...prev, fijo: value }))}
          >
            Turno fijo
          </CheckBox>
          {turno?.eraFijo && (
            <Tooltip text="No puede desactivar esta opción porque el turno ya fue copiado a la semana siguiente" />
          )}
        </StyledTurnoFijoLayout>
      </Modal.Body>
      <Modal.Footer>
        {secondaryActions.length === 1 ? (
          <Button color="white" onClick={() => actionClick(secondaryActions[0])}>
            {secondaryActions[0].label}
          </Button>
        ) : (
          secondaryActions.length > 1 && (
            <ButtonSelect
              value={secondaryActions[0].label}
              options={secondaryActions.slice(1)}
              onClick={() => actionClick(secondaryActions[0])}
              onClickOption={(action) => actionClick(action)}
            />
          )
        )}

        <Button color="primary" disabled={!isFormValid()} onClick={() => actionClick(action.main)}>
          {action.main.label}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ModalAdmin;
