import React from "react";
import { StyledClubName, StyledContainer, StyledDataContainer, StyledDireccion, StyledLogo } from "./InfoDireccion.styles";

const InfoDireccion = ({ club }) => {
  return <StyledContainer>
    <StyledLogo src={club?.logoUrl}></StyledLogo>
    <StyledDataContainer>
        <StyledClubName>{club?.name}</StyledClubName>
        <StyledDireccion>{club?.address}</StyledDireccion>
    </StyledDataContainer>
  </StyledContainer>;
};

export default InfoDireccion;
