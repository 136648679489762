import React, { useLayoutEffect } from "react";
import { StyledContainerDay, StyledDia, StyledSelectedBackground, StyledText } from "./CalendarButtons.styles";

const diasSemana = ["Dom.", "Lun.", "Mar.", "Mié.", "Jue.", "Vie.", "Sáb."];

const CalendarButtons = ({ diaSeleccionado, onSelectDay, displayedDays = 0 }) => {
  //array of strings (yyyy-mm-dd) starting from today and adding 5 days
  const today = new Date();
  today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
  const fechas = [];

  const diaRef = React.useRef();

  for (let i = 0; i < displayedDays; i++) {
    const date = new Date(today);
    date.setDate(date.getDate() + i);
    fechas.push(date.toISOString().split("T")[0]);
  }

  const indexSeleccionado = fechas.findIndex((fecha) => {
    const fechaStr = fecha;
    return fechaStr === diaSeleccionado;
  });

  const [diaWidth, setDiaWidth] = React.useState(0);

  useLayoutEffect(() => {
    if (diaRef.current) {
      setDiaWidth(diaRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (diaRef.current) {
        setDiaWidth(diaRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledContainerDay>
      {fechas.map((fechaStr, i) => {
        const fecha = new Date(fechaStr);
        // take into account the timezone
        fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset());

        const diaSemana = diasSemana[fecha.getDay()];
        const numDia = fecha.getDate();
        const active = indexSeleccionado === i;
        return (
          <StyledDia
            ref={i === 0 ? diaRef : undefined}
            key={`${diaSemana}+${numDia}`}
            active={active}
            onClick={(e) => {
              if (!active) {
                onSelectDay(fechaStr);
                e.target.scrollIntoView({ inline: "center", behavior: "smooth" });
              }
            }}
          >
            <StyledText size="md">{numDia}</StyledText>
            <StyledText size="sm">{diaSemana}</StyledText>
          </StyledDia>
        );
      })}
      <StyledSelectedBackground
        style={{
          width: diaWidth,
          transform: `translateX(${indexSeleccionado * 100}%)`,
        }}
      />
    </StyledContainerDay>
  );
};

export default CalendarButtons;
