import {
  signInWithPopup,
  signInWithRedirect,
  signOut as firebaseSignOut,
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, googleProvider, logEvent, removeFCMToken } from "../firebase";
import { createUser } from "../firebase/users";
import { isIOSApp } from "./ios";

export const afterLoginHandler = async (result, name, phone) => {
  const { isNewUser } = getAdditionalUserInfo(result);
  if (isNewUser) {
    logEvent("register");
    const user = result.user;
    const userData = {
      email: user.email,
      displayName: name || user.displayName,
      originalName: name || user.displayName,
      photoURL: user.photoURL || "",
      phone: phone || "",
    };
    await createUser(user.uid, userData);
  } else {
    logEvent("login");
  }
};

const login = async (provider = googleProvider) => {
  if (isIOSApp()) {
    signInWithRedirect(auth, provider);
  } else {
    const result = await signInWithPopup(auth, provider);
    await afterLoginHandler(result);
  }
};

const loginEmail = async (email, password) => {
  const result = await signInWithEmailAndPassword(auth, email, password);
  await afterLoginHandler(result);
};

const signupEmail = async (email, password, name, phone) => {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  await afterLoginHandler(result, name, phone);
  await sendEmailVerification(auth.currentUser);
};

const logout = async () => {
  try {
    await removeFCMToken();
    firebaseSignOut(auth);
  } catch (error) {
    console.error(error);
  }
};

const forgotPassword = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

export const removeAccount = async () => {
  if (
    // eslint-disable-next-line no-restricted-globals
    !confirm("¿Estas seguro que quieres eliminar tu cuenta?, esta acción es irreversible y perderás todos tus datos.")
  ) {
    return;
  }

  // eslint-disable-next-line no-restricted-globals
  if (!confirm("Por favor, vuelve a confirmar que quieres eliminar tu cuenta.")) {
    return;
  }

  try {
    await auth.currentUser.delete();
  } catch (e) {
    if (e.code === "auth/requires-recent-login") {
      if (auth.currentUser.providerData.some((provider) => provider.providerId === "password")) {
        const password = prompt("Por favor, ingresa tu contraseña para confirmar la eliminación de tu cuenta.");
        if (!password) {
          return;
        }
        await loginEmail(auth.currentUser.email, password);
      } else {
        await login();
      }

      await auth.currentUser.delete();
    }
  }
};

export { login, logout, loginEmail, signupEmail, forgotPassword };
