import { styled } from "../../theme/styled";

export const StyledCardContainer = styled("div", {
  shadow: 1,
  height: 60,
  borderRadius: 16,
  display: "flex",
  flexDirection: "row",
  marginBottom: 12,
});

export const StyledContainerIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: 16,
  borderBottomLeftRadius: 16,
  marginRight: 12,
  width: 60,
  height: 60,
  variants: {
    color: {
      red: {
        gradient: "$red",
      },
      green: {
        gradient: "$green",
      },
      blue: {
        gradient: "$blue",
      },
      dark: {
        gradient: "$dark",
      },
      yellow: {
        gradient: "$yellow",
      },
    },
  },
});

export const StyledIcon = styled("p", {
  color: "$white",
  fontSize: 32,
});

export const StyledTitle = styled("h1", {
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
});

export const StyledSubTitle = styled("h1", {
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "19px",
});

export const StyledContainerData = styled("div", {
  marginTop: 6,
});
