import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getClub } from "../firebase/clubs";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import CalendarButtons from "../components/CalendarButtons/CalendarButtons";
import HorariosLayout from "../components/HorariosLayout/HorariosLayout";
import Button from "../components/Button/Button";
import TurnoCard from "../components/TurnoCard/TurnoCard";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import { getCanchas } from "../firebase/canchas";
import useTurnosByCanchas from "../hooks/useTurnosByCanchas";
import useGroupTurnosByTime from "../hooks/useGroupTurnosByTime";
import NoInternet from "../components/NoInternet/NoInternet";
import useIsOnline from "../hooks/useIsOnline";
import { fechaPasada } from "../utils/fechas";
import { AlertsContext } from "../utils/alerts";
import TurnoModal from "../components/TurnoModal/TurnoModal";
import { useUserData } from "../utils/userData";
import CardTurnoAdmin from "../components/CardTurnoAdmin/CardTurnoAdmin";
import NoResults from "../components/NoResults/NoResults";
import AdminCalendar from "../components/AdminCalendar/AdminCalendar";

const HorarioPage = () => {
  const today = new Date();
  today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
  const isOnline = useIsOnline();
  const { clubId } = useParams();
  const [club, setClub] = useState(null);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(today.toISOString().split("T")[0]);
  const [canchas, setCanchas] = useState(null);
  const [selectedCanchas, setSelectedCanchas] = useState([]);
  const { turnosDisponibles, turnosOcupados, users } = useTurnosByCanchas(club, selectedCanchas, fechaSeleccionada);
  const turnosDisponiblesAgrupados = useGroupTurnosByTime(turnosDisponibles);
  const turnos = [...turnosOcupados, ...turnosDisponiblesAgrupados].sort((a, b) => {
    if (a.hora < b.hora) {
      return -1;
    }
    if (a.hora > b.hora) {
      return 1;
    }
    return 0;
  });
  const [show, setShow] = useState(false);
  const { addAlert } = useContext(AlertsContext);
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(null);
  const loadingTurnos = !turnos.length;
  const loading = !club || !canchas;
  const cardsContainerRef = useRef(null);
  const user = useUserData();
  const userIsAdmin = !!club?.admins?.[user?.uid];

  useEffect(() => {
    getClub(clubId)
      .then((data) => setClub(data))
      .catch(() => {
        console.error("Error al obtener el club");
      });
    getCanchas(clubId)
      .then((data) => {
        setCanchas(data);
        setSelectedCanchas(data);
      })
      .catch(() => {
        console.error("Error al obtener las canchas");
      });
  }, [clubId]);

  useLayoutEffect(() => {
    cardsContainerRef.current?.scrollTo({ top: 0 });
  }, [loadingTurnos]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (turno) => {
    if (!userIsAdmin) {
      if (fechaPasada(turno.fecha, turno.hora, club.timezone)) {
        addAlert({
          type: "toast",
          message: "Este turno ya no está disponible",
        });
        return;
      }
      if (turno.estado === "DISPONIBLE") {
        openModal(turno);
      }
    } else {
      openModal(turno);
    }
  };
  const openModal = (turno) => {
    setShow(true);
    setTurnoSeleccionado(turno);
  };

  const renderTurnoCard = (turno) => {
    const turnoKey = turno.id || `${turno.hora}-${turno.horaFin}`;
    const canchaName = canchas.find((cancha) => turno.canchaIds.includes(cancha.id))?.name;
    if (userIsAdmin) {
      return (
        <CardTurnoAdmin
          key={turnoKey}
          turno={turno}
          horaFin={turno.horaFin}
          fijo={turno.fijo || turno.eraFijo}
          user={users?.[turno.user?.id]}
          onClick={() => handleShow(turno)}
          name={turno.name}
          cancha={canchaName}
          showCancha={selectedCanchas.length > 1}
        />
      );
    } else {
      const turnoName =
        !!turno.user?.id && turno.user.id === user?.uid ? user.displayName : users?.[turno.user?.id]?.name;
      return (
        <TurnoCard
          key={turnoKey}
          estado={turno.estado}
          name={turnoName}
          cancha={turnoName ? canchaName : null}
          hora={turno.hora}
          horaFin={turno.horaFin}
          onClick={() => {
            handleShow(turno);
          }}
        />
      );
    }
  };

  const renderTurnos = () => {
    if (!isOnline) {
      return <NoInternet />;
    }
    if (loadingTurnos) {
      return new Array(20).fill(0).map((_, i) => <TurnoCard key={i} loading />);
    }
    const turnosVisibles = userIsAdmin
      ? turnos
      : turnos?.filter((turno) => !fechaPasada(turno.fecha, turno.hora, club.timezone)) || [];
    if (turnosVisibles.length > 0) {
      return turnosVisibles.map(renderTurnoCard);
    }
    return (
      <NoResults
        style={{ paddingTop: "100px" }}
        msg={
          selectedCanchas.length === 1
            ? `No quedan turnos disponibles para este día en la cancha seleccionada`
            : "No quedan turnos disponibles para este día"
        }
      />
    );
  };

  if (loading) {
    return <SmallLoader visible />;
  }
  return (
    <HorariosLayout>
      <HorariosLayout.Top>
        <Breadcrumb
          pages={[
            { label: club?.name, url: `/club/${club?.id}`, name: "club" },
            { label: "Horarios", name: "horarios" },
          ]}
        ></Breadcrumb>
        {userIsAdmin ? (
          <AdminCalendar
            diaSeleccionado={fechaSeleccionada}
            onSelectDay={(dia) => {
              setFechaSeleccionada(dia);
            }}
          />
        ) : (
          <CalendarButtons
            diaSeleccionado={fechaSeleccionada}
            displayedDays={club?.displayedDays}
            onSelectDay={(dia) => {
              setFechaSeleccionada(dia);
            }}
          ></CalendarButtons>
        )}
      </HorariosLayout.Top>

      <HorariosLayout.Cards loading={loadingTurnos} ref={cardsContainerRef}>
        {renderTurnos()}
      </HorariosLayout.Cards>
      <HorariosLayout.Buttons>
        <Button
          size="md"
          color={selectedCanchas.length === canchas.length ? "primary" : "white"}
          onClick={() => {
            setSelectedCanchas(canchas);
          }}
        >
          Todas las canchas
        </Button>
        {canchas.map((cancha) => (
          <Button
            size="md"
            color={
              selectedCanchas.length === 1 && selectedCanchas.some((c) => c.id === cancha.id) ? "primary" : "white"
            }
            key={cancha.id}
            onClick={() => {
              setSelectedCanchas([cancha]);
            }}
          >
            {cancha.name}
          </Button>
        ))}
      </HorariosLayout.Buttons>
      <TurnoModal
        show={show}
        handleClose={handleClose}
        turno={turnoSeleccionado}
        canchas={turnoSeleccionado ? canchas.filter((cancha) => turnoSeleccionado.canchaIds.includes(cancha.id)) : []}
        club={club}
      ></TurnoModal>
    </HorariosLayout>
  );
};

export default HorarioPage;
