import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getAdminClubs } from "../firebase/clubs";

const AdminClubsContext = createContext({ adminClubs: [], isAdmin: false });
export const AdminClubsProvider = AdminClubsContext.Provider;

const useAdminClubs = () => {
  const data = useContext(AdminClubsContext);
  return data;
};

export const useGetAdminClubs = (user) => {
  const [adminClubs, setAdminClubs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      getAdminClubs(user.uid)
        .then((clubs) => {
          setAdminClubs(clubs);
          setIsAdmin(clubs.length > 0);
        })
        .catch((e) => {
          console.error("Error getting admin clubs");
        });
    } else {
      setAdminClubs([]);
      setIsAdmin(false);
    }
  }, [user?.uid]);

  const api = useMemo(
    () => ({
      adminClubs,
      isAdmin,
    }),
    [adminClubs, isAdmin]
  );

  return api;
};

export default useAdminClubs;
