import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import ClubCardFacturacion from "../components/ClubCardFacturacion/ClubCardFacturacion";
import ClubSelector from "../components/ClubSelector/ClubSelector";
import Container from "../components/Container/Container";
import Facturacion from "../components/FacturacionLayout/FacturacionLayout";
import PeriodCard from "../components/PeriodCard/PeriodCard";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import Title from "../components/Title/Title";
import { getAdminClubs, getClubPaymentInfo } from "../firebase/clubs";
import { getPeriods } from "../firebase/periods";
import { useUserData } from "../utils/userData";

const FacturacionPage = () => {
  const user = useUserData();
  const [selectedClub, setSelectedClub] = useState();
  const [clubs, setClubs] = useState([]);

  const onSelectClub = (clubId) => {
    setSelectedClub(clubId);
  };

  useEffect(() => {
    (async () => {
      if (user?.uid) {
        const clubs = await getAdminClubs(user.uid);
        for (const club of clubs) {
          const periods = await getPeriods(club.id);
          club.periods = periods;

          const paymentInfo = await getClubPaymentInfo(club.id);
          const saldo =
            club.periods.reduce((acc, period) => {
              if (period.status === "closed") {
                return acc - (period.qty * period.unitPrice - (period.totalPaid || 0));
              } else {
                return acc;
              }
            }, 0) + (paymentInfo?.favorBalance || 0);

          club.saldo = saldo;
        }
        setClubs(clubs);
        onSelectClub(clubs[0]?.id);
      }
    })();
  }, [user?.uid]);

  return (
    <Facturacion>
      <Container>
        <Breadcrumb
          pages={[
            { label: "Administración", url: "/admin", name: "admin" },
            { label: "Facturación", name: "facturacion" },
          ]}
        />
      </Container>
      <SmallLoader visible={!clubs.length} />
      {clubs.length > 1 ? (
        <>
          <ClubSelector value={selectedClub} onSelect={onSelectClub} clubs={clubs} />
          <ClubCardFacturacion club={clubs.find((e) => e.id === selectedClub)} />
        </>
      ) : clubs.length === 1 ? (
        <ClubCardFacturacion club={clubs[0]} />
      ) : null}
      {selectedClub ? (
        <>
          <Container>
            <Title size="lg">Detalle</Title>
          </Container>
          <Facturacion.PeriodContainer>
            {clubs
              .find((club) => club.id === selectedClub)
              ?.periods.map((period) => (
                <PeriodCard key={period.id} period={period} />
              ))}
          </Facturacion.PeriodContainer>
        </>
      ) : null}
    </Facturacion>
  );
};

export default FacturacionPage;
