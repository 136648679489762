import { styled } from "../../theme/styled";

export const StyledUserName = styled("h1", {
  fontWeight: 500,
  fontSize: 24,
  lineHeight: "33px",
  color: "$dark",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  marginTop: "11px",
  marginBottom: "10px",
});
