import { styled } from "../../theme/styled";

export const StyledLoadingScreen = styled("div", {
  opacity: 0,
  visibility: "hidden",
  transition: "visibility 0.3s ease, opacity 0.3s ease",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  variants: {
    visible: {
      true: {
        opacity: 1,
        visibility: "visible",
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99999,
      },
    },
    modal: {
      true: {
        position: "absolute",
        borderRadius: "16px",
      },
    },
  },
});
