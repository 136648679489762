import { styled } from "../../theme/styled";
import { FaWhatsapp } from "react-icons/fa";

export const StyledWhatsAppBtn = styled("a", {
  padding: "0 16px",
  border: "none",
  alignItems: "center",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
  borderRadius: "10em",
  shadow: 1,
  cursor: "pointer",
  gradient: "$whatsapp",
  color: "$white",
  height: 42,
  display: "flex",
  justifyContent: "center",
  gap: 8,
});

export const StyledIcon = styled(FaWhatsapp, {
  fontSize: 24,
});
