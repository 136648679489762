import {
  StyledAccountLayout,
  StyledInputContainer,
  StyledNameContainer,
  StyledPolicyLink,
  StyledNotifContainer,
  StyledNotifTitleContainer,
  StyledLogoutContainer,
  StyledRemoveAccountLink
} from "./AccountLayout.styles";

const AccountLayout = StyledAccountLayout;

AccountLayout.NameContainer = StyledNameContainer;

AccountLayout.InputContainer = StyledInputContainer;

AccountLayout.NotifContainer = StyledNotifContainer;

AccountLayout.NotifTitleContainer = StyledNotifTitleContainer;

AccountLayout.LogoutContainer = StyledLogoutContainer;

AccountLayout.PolicyLink = StyledPolicyLink;

AccountLayout.RemoveAccountLink = StyledRemoveAccountLink;

export default AccountLayout;
