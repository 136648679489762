import { styled } from "../../theme/styled";

export const StyledContainerCard = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: 214,
  borderRadius: 16,
  shadow: 1,
});

export const StyledContainerImage = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 130,
});

export const StyledImage = styled("img", {
  borderStartStartRadius: 16,
  borderStartEndRadius: 16,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  backgroundColor: "$light",
});

export const StyledContainerData = styled("div", {
  padding: 12,
});

export const StyledClubName = styled("h1", {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  color: "$dark",
});

export const StyledClubDescription = styled("p", {
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "19px",
  marginBottom: 4,
  height: 19,
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const StyledClubKm = styled("p", {
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "19px",
  color: "$gray",
  position: "absolute",
  bottom: 12,
  right: 12,
});
