import React from "react";
import {
  StyledBox,
  StyledBoxIcon,
  StyledCheckBox,
  StyledCheckContainer,
  StyledText,
  StyledCheckLabel,
} from "./CheckBox.styles";

const CheckBox = ({ value, onChange, children, disabled }) => {
  return (
    <StyledCheckContainer>
      <StyledCheckLabel>
        <StyledCheckBox
          disabled={!!disabled}
          type="checkbox"
          checked={value}
          onChange={(e) => {
            if (!disabled) {
              onChange(e.target.checked);
            }
          }}
        />
        <StyledBox checked={value} disabled={!!disabled}>
          <StyledBoxIcon />
        </StyledBox>
        <StyledText>{children}</StyledText>
      </StyledCheckLabel>
    </StyledCheckContainer>
  );
};

export default CheckBox;
