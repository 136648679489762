import { styled } from "../../theme/styled";

export const StyledAdminTurnoPageContainer = styled("div", {
  paddingX: 16,
  width: "100%",
});

export const StyledTitleContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
});

export const StyledSeparation = styled("div", {
  borderBottom: "1px solid $light",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "20px",
  marginBottom: "20px",
});

export const StyledClubContainer = styled("div", {
  "&:not(:last-child)": {
    paddingBottom: "24px",
    borderBottom: "1px solid $light",
  },
  "&:not(:first-child)": {
    paddingTop: "24px",
  },
});
