import { collection, doc, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from ".";

const onNotificationsSnapshot = (userId, onChange) => {
  const userRef = doc(db, "users", userId);

  const notificationRef = collection(db, "notifications");
  const q = query(notificationRef, where("user", "==", userRef), orderBy("fecha", "desc"), limit(40));

  return onSnapshot(q, (snapshots) => {
    const notifications = [];
    if (snapshots.docs) {
      snapshots.docs.forEach((doc) => {
        notifications.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      onChange(notifications);
    }
  });
};

const updateNotification = async (id, data) => {
  try {
    const notificationRef = doc(db, "notifications", id);
    return setDoc(notificationRef, data, { merge: true });
  } catch (e) {
    console.error("JugaYa: Error updating notification", e);
  }
};

export { updateNotification, onNotificationsSnapshot };
