import { styled } from "../../theme/styled";

export const StyledParagraph = styled("p", {
  fontWeight: 400,
  lineHeight: "19px",
  marginBottom: 8,
  variants: {
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    size: {
      sm: {
        fontSize: 14,
      },
      md: {
        fontSize: 16,
      },
    },
    margin: {
      none: {
        marginBottom: 0,
      },
      bottom: {
        marginBottom: 8,
      },
      top: {
        marginTop: 8,
      },
      both: {
        marginBottom: 8,
        marginTop: 8,
      },
    },
  },
});
