import React from "react";
import { StyledSelect, StyledSelectLabel, StyledSelectIcon, StyledSelectContainer } from "./Select.styles";

const Select = ({ options, value, onChange, label, disabled }) => {
  return (
    <StyledSelectContainer>
      <StyledSelectLabel>
        {label}
        <StyledSelect disabled={disabled} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
        <StyledSelectIcon />
      </StyledSelectLabel>
    </StyledSelectContainer>
  );
};

export default Select;
