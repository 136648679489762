import { useEffect, useState } from "react";

const useIsOnline = () => {
  const [connectionState, setConnectionState] = useState(navigator.onLine);

  useEffect(() => {
    const handleConnectionOnline = () => {
      setConnectionState(true);
    };
    const handleConnectionOffline = () => {
      setConnectionState(false);
    };

    window.addEventListener("online", handleConnectionOnline);
    window.addEventListener("offline", handleConnectionOffline);

    return () => {
      window.removeEventListener("online", handleConnectionOnline);
      window.removeEventListener("offline", handleConnectionOffline);
    };
  }, []);

  return connectionState;
};

export default useIsOnline;
