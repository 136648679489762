import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import ClubCard from "../components/ClubCard/ClubCard";
import Header from "../components/Header/Header";
import SportSelector from "../components/SportSelector/SportSelector";
import Title from "../components/Title/Title";
import { getClubsByCity } from "../firebase/clubs";
import MsgUnirse from "../components/MsgUnirse/MsgUnirse";
import NoResults from "../components/NoResults/NoResults";
import HomeLayout from "../components/HomeLayout/HomeLayout";
import { getCache, saveCache } from "../utils/cache";
import NotificationIcon from "../components/NotificationIcon/NotificationIcon";
import { useUserData } from "../utils/userData";
import Container from "../components/Container/Container";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import SelectLocation from "../components/SelectLocation/SelectLocation";
import { getCities } from "../firebase/cities";
import { isIOSApp } from "../utils/ios";

const HomePage = () => {
  const user = useUserData();
  const [show, setShow] = useState(false);
  const [clubs, setClubs] = useState();
  const [selectedSport, setSelectedSport] = useState(getCache("defaultSport") || "all");
  const [cities, setCities] = useState({});
  const [selectedCity, setSelectedCity] = useState(getCache("defaultCity", true));
  const navigate = useNavigate();
  useEffect(() => {
    getCities()
      .then((cities) => {
        setCities(cities);
        if (cities.list?.length === 1) {
          setSelectedCity(cities.list[0]);
          saveCache("defaultCity", cities.list[0], true);
        }
      })
      .catch(() => {
        console.error("Error al obtener las ciudades");
      });
  }, []);

  useEffect(() => {
    getClubsByCity(selectedCity)
      .then((clubs) => setClubs(clubs))
      .catch(() => {
        console.error("Error al obtener los complejos");
      });
  }, [selectedCity]);

  const visibleClubs = useMemo(
    () => clubs?.filter((club) => selectedSport === "all" || club.sport === selectedSport),
    [clubs, selectedSport]
  );

  return (
    <HomeLayout>
      <HomeLayout.FixedPart>
        <Header
          actions={[
            {
              name: "notificaciones",
              icon: NotificationIcon,
              onClick: () => navigate("/notif"),
              visible: !!user?.uid,
            },
          ]}
          showLogo
          showLocation
          onLocationClick={() => setShow(true)}
          selectedCity={selectedCity}
          cities={cities}
        />
        <Container>
          <Title size="lg">Deportes</Title>
        </Container>

        <SportSelector
          value={selectedSport}
          onSelect={(sport) => {
            setSelectedSport(sport);
            saveCache("defaultSport", sport);
          }}
        />
      </HomeLayout.FixedPart>
      <HomeLayout.ScrollPart>
        <Title size="lg">Complejos cerca</Title>
        <HomeLayout.ClubsGrid>
          {!visibleClubs ? (
            <SmallLoader visible />
          ) : visibleClubs.length === 0 ? (
            <NoResults
              msg={
                <>
                  Por el momento no hay complejos
                  <br /> cerca de tí
                </>
              }
            />
          ) : (
            visibleClubs.map((club) => (
              <ClubCard key={club.id} rounded onClick={() => navigate(`/club/${club.id}`)} club={club}></ClubCard>
            ))
          )}
        </HomeLayout.ClubsGrid>

        <HomeLayout.BottomPart hidden={isIOSApp()}>{!isIOSApp() && <MsgUnirse />}</HomeLayout.BottomPart>
      </HomeLayout.ScrollPart>
      <SelectLocation
        show={show || (!selectedCity && cities.list?.length > 1)}
        onClose={() => setShow(false)}
        cities={cities}
        onSelect={(cityId) => {
          setSelectedCity(cityId);
          saveCache("defaultCity", cityId, true);
        }}
      />
    </HomeLayout>
  );
};

export default HomePage;
