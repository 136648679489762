import { styled } from "../../theme/styled";
import { FiInfo } from "react-icons/fi";

export const FullPage = styled("div", {
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  overflow: "auto",
  height: "100%",
});

export const PicturePart = styled("div", {
  height: 240,
  flex: "0 0 240px",
});

export const ContentPart = styled("div", {
  flex: 1,
  padding: 20,
  borderRadius: "32px 32px 0px 0px",
  backgroundColor: "$white",
  zIndex: 10,
  position: "relative",
  shadow: 3,
});

export const TagsContainer = styled("div", {
  marginBottom: 16,
});

export const InfoButton = styled(FiInfo, {
  position: "absolute",
  top: 20,
  right: 20,
  width: 24,
  height: 24,
  color: "$dark",
  cursor: "pointer",
});

export const StyledRankingContainer = styled("div", {
  display: "grid",
  marginTop: 16,
  gap: 16,
});
