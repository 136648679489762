import React, { useState } from "react";
import estadoTurno from "../../utils/estadoTurno";
import { formatDate, formatTimestamp } from "../../utils/fechas";

import {
  StyledCardAdmin,
  StyledCardEstado,
  StyledCardHora,
  StyledCardUser,
  StyledContainerData,
  StyledFijoIcon,
  StyledHoraEstadoRow,
  StyledHoraFin,
  StyledOpenButton,
  StyledTurnoContainer,
  StyledTurnoData,
  StyledUserContainer,
  StyledUserData,
  StyledUserImg,
} from "./CardTurnoAdmin.styles";

const CardTurnoAdmin = ({ turno, user, onClick, fijo, showDate, size = "md", horaFin, cancha, showCancha }) => {
  const [isOpen, setIsOpen] = useState(false);
  const userName = user?.displayName || turno.name;
  const fechaStr = formatDate(turno.fecha);
  const realIsOpen = isOpen && !!user;
  
  const renderUserData = () => {
    if (user) {
      return (
        <StyledUserContainer>
          <StyledUserImg
            isOpen={realIsOpen}
            src={user?.photoURL || "/img/default-user-image.png"}
            alt={userName}
          ></StyledUserImg>
          <StyledContainerData>
            <StyledUserData size={"md"}>
              {!realIsOpen && showCancha ? `${cancha} - ${userName}` : userName}
            </StyledUserData>
            <StyledUserData isOpen={realIsOpen} size={"sm"}>
              {user?.email}
            </StyledUserData>
            <StyledUserData isOpen={realIsOpen} size={"sm"}>
              {user?.phone}
            </StyledUserData>
          </StyledContainerData>
        </StyledUserContainer>
      );
    } else {
      const renderNameField = () => {
        const parts = [];
        if (showCancha && turno.estado !== "DISPONIBLE") {
          parts.push(cancha);
        }
        if (turno.name) {
          parts.push(turno.name);
        }
        return parts.join(" - ");
      };

      return (
        <StyledContainerData>
          <StyledUserData size={"md"}>{renderNameField()}</StyledUserData>
        </StyledContainerData>
      );
    }
  };

  return (
    <StyledCardAdmin
      isOpen={realIsOpen}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else if (user) {
          setIsOpen(!realIsOpen);
        }
      }}
    >
      <StyledHoraEstadoRow>
        <StyledCardHora size={size}>
          {turno.hora}
          {horaFin && <StyledHoraFin>a {horaFin}</StyledHoraFin>}
          {showDate ? " - " + fechaStr : ""}
        </StyledCardHora>
        <StyledCardEstado size={size} estado={turno.estado}>
          {fijo && <StyledFijoIcon />}
          {estadoTurno[turno.estado]}
        </StyledCardEstado>
      </StyledHoraEstadoRow>
      <StyledCardUser>{renderUserData()}</StyledCardUser>
      <StyledTurnoContainer>
        <StyledTurnoData isOpen={realIsOpen} size={"sm"}>
          <b>Cancha: </b>
          {cancha}
        </StyledTurnoData>
        <StyledTurnoData isOpen={realIsOpen} size={"sm"}>
          <b>Pedido el: </b>
          {!!turno.diaDePedido ? formatTimestamp(turno.diaDePedido) : "Información no disponible"}
        </StyledTurnoData>
      </StyledTurnoContainer>
      {user && (
        <StyledOpenButton
          isOpen={realIsOpen}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!realIsOpen);
          }}
        />
      )}
    </StyledCardAdmin>
  );
};

export default CardTurnoAdmin;
