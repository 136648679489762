import {
  StyledEnlaceLayout,
  StyledEnlaceLayoutBody,
  StyledEnlaceLayoutEnlaces,
  StyledEnlaceLayoutTitle,
  StyledHorariosLayoutButton,
} from "./EnlaceLayout.styles";

const EnlaceLayout = StyledEnlaceLayout;

EnlaceLayout.Body = StyledEnlaceLayoutBody;

EnlaceLayout.Title = StyledEnlaceLayoutTitle;

EnlaceLayout.Button = StyledHorariosLayoutButton;

EnlaceLayout.Enlaces = StyledEnlaceLayoutEnlaces;

export default EnlaceLayout;
