import React from "react";
import FakeComponent from "../FakeComponent/FakeComponent";
import {
  StyledCardContainer,
  StyledContainerData,
  StyledContainerIcon,
  StyledIcon,
  StyledSubTitle,
  StyledTitle,
} from "./CardAdmin.styles";

const CardAdmin = ({
  title,
  subtitle,
  color,
  loading,
  icon: Icon,
  onClick,
}) => {
  return (
    <StyledCardContainer onClick={!loading ? onClick : undefined}>
      <StyledContainerIcon color={color}>
        <StyledIcon>{Icon}</StyledIcon>
      </StyledContainerIcon>
      <StyledContainerData>
        {loading ? (
          <FakeComponent style={{ width: 100, height: 20 }} />
        ) : (
          <StyledTitle>{title}</StyledTitle>
        )}
        {loading ? (
          <FakeComponent
            style={{ width: 200, height: 16, marginTop: 4, display: "block" }}
          />
        ) : (
          <StyledSubTitle>{subtitle}</StyledSubTitle>
        )}
      </StyledContainerData>
    </StyledCardContainer>
  );
};

export default CardAdmin;
