import React from "react";
import FakeComponent from "../FakeComponent/FakeComponent";
import { StyledTitle } from "./Title.styles";

const Title = ({ children, loading, size = "sm" }) => {
  if (loading) {
    return <FakeComponent style={{width: 240, height:30, marginBottom: 15}} />;
  }
  return <StyledTitle size={size}>{children}</StyledTitle>;
};

export default Title;
