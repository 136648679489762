import React, { useContext, useMemo } from "react";
import Button from "../components/Button/Button";
import Container from "../components/Container/Container";
import Header from "../components/Header/Header";
import NotificationCard from "../components/NotificationCard/NotificationCard";
import NotificationLayout from "../components/NotificationLayout/NotificationLayout";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import { updateNotification } from "../firebase/notifications";
import { NotificationsContext } from "../utils/notifications";

const NotificationsPage = () => {
  const notifications = useContext(NotificationsContext);
  const count = useMemo(() => notifications.filter((e) => !e.read).length, [notifications]);
  return (
    <NotificationLayout>
      <ScrollLayout>
        <ScrollLayout.FixedPart>
          <Header title="Notificaciones" />
          <Container>
            {count > 0 ? (
              <Button
                color="white"
                size="sm"
                onClick={() => {
                  notifications.forEach((notification) => {
                    if (!notification.read) {
                      updateNotification(notification.id, { read: true });
                    }
                  });
                }}
              >
                Marcar todas como leídas
              </Button>
            ) : null}
          </Container>
        </ScrollLayout.FixedPart>
        <ScrollLayout.ScrollPart>
          <Container>
            <NotificationLayout.NotificationCard>
              {notifications.map((notification) => (
                <NotificationCard key={notification.id} notification={notification}></NotificationCard>
              ))}
            </NotificationLayout.NotificationCard>
          </Container>
        </ScrollLayout.ScrollPart>
      </ScrollLayout>
    </NotificationLayout>
  );
};

export default NotificationsPage;
