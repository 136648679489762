import React from "react";
import { FiHome, FiCalendar, FiBookmark, FiLogIn } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useUserData } from "../../utils/userData";
import { StyledBottomBarIcon, StyledContainer, StyledLabel } from "./BottomBar.styles";
import { HiOutlineMenu } from "react-icons/hi";
import useAdminClubs from "../../hooks/useAdminClubs";

const BottomBar = () => {
  const navigate = useNavigate();
  const user = useUserData();
  const { isAdmin } = useAdminClubs();
  const currentPath = window.location.pathname.split("/")?.[1] || "";
  const pagesList = [
    {
      icon: FiHome,
      page: "/",
      name: "inicio",
      label: "Inicio",
    },
    {
      icon: FiCalendar,
      page: "/admin",
      name: "admin",
      admin: true,
      label: "Admin.",
    },
    {
      icon: FiBookmark,
      page: "/mis-reservas",
      name: "reservas",
      show: "logged",
      label: "Reservas",
    },
    {
      icon: FiLogIn,
      name: "login",
      label: "Ingresar",
      page: "/signin",
      show: "not-logged",
    },
    {
      icon: HiOutlineMenu,
      name: "menu",
      label: "Menú",
      onClick: () => {
        if (currentPath === "menu") {
          navigate(-1);
        } else {
          navigate("/menu");
        }
      },
    },
  ];
  const currentPage = pagesList.find((page) => page.page === `/${currentPath}`);

  return (
    <StyledContainer>
      {pagesList
        .filter((page) => {
          if ((page.show === "logged" && !user) || (page.show === "not-logged" && user) || (page.admin && !isAdmin)) {
            return false;
          }

          return true;
        })
        .map(({ icon: IconComponent, page, name, label, onClick }) => (
          <StyledBottomBarIcon
            selected={page && page === currentPage?.page}
            key={name}
            onClick={async () => {
              if (onClick) {
                onClick();
              } else {
                navigate(page);
              }
            }}
          >
            <IconComponent />
            <StyledLabel>{label}</StyledLabel>
          </StyledBottomBarIcon>
        ))}
    </StyledContainer>
  );
};

export default BottomBar;
