import React from "react";
import Button from "../Button/Button";
import { StyledCloseButton, StyledContainerCloseButton, StyledActionAlert, StyledSpan } from "./ActionAlert.styles";

const ActionAlert = ({ visible, icon: Icon, handleClose, text, action }) => {
  return (
    <StyledActionAlert visible={visible}>
      <Icon />
      <StyledSpan>{text}</StyledSpan>
      {action && (
        <Button color="link" size="xs" onClick={action.onClick}>
          {action.label}
        </Button>
      )}
      <StyledContainerCloseButton onClick={handleClose}>
        <StyledCloseButton>x</StyledCloseButton>
      </StyledContainerCloseButton>
    </StyledActionAlert>
  );
};

export default ActionAlert;
