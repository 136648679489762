import React, { useContext } from "react";
import { AlertsContext } from "../../utils/alerts";
import NotificationToast from "../NotificationToast/NotificationToast";

const NotificationsContainers = () => {
  const { notifications } = useContext(AlertsContext);
  return notifications.map((notification) => <NotificationToast key={notification.id} notification={notification} />);
};

export default NotificationsContainers;
