import React from "react";
import { StyledNoInternetButton, StyledNoInternetContainer } from "./NoInternet.styles";
import NoResults from "../NoResults/NoResults";

const NoInternet = () => {
  return (
    <StyledNoInternetContainer>
      <NoResults msg="No tienes conexión a internet"></NoResults>
      <StyledNoInternetButton onClick={() => window.location.reload()}>Reintentar</StyledNoInternetButton>
    </StyledNoInternetContainer>
  );
};

export default NoInternet;
