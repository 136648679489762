import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from ".";

const getClubs = async () => {
  const q = query(collection(db, "clubs"), where(`visible`, "==", true));
  const querySnapshot = await getDocs(q);
  const clubs = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    clubs.push({
      id: doc.id,
      ...data,
    });
  }
  return clubs;
};

const getClub = async (clubId) => {
  const clubRef = doc(db, "clubs", clubId);
  const clubDoc = await getDoc(clubRef);
  let result;
  if (clubDoc.exists()) {
    result = {
      id: clubDoc.id,
      ...clubDoc.data(),
    };
  } else {
    result = undefined;
  }
  return result;
};

const getAdminClubs = async (userId) => {
  const clubs = [];

  // Clubes donde admins contiene userId
  const q = query(collection(db, "clubs"), where(`admins.${userId}`, "==", true));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    clubs.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return clubs;
};

const getClubsByCity = async (cityId) => {
  const q = query(collection(db, "clubs"), where(`visible`, "==", true), where(`city`, "==", cityId));
  const querySnapshot = await getDocs(q);
  const clubs = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    clubs.push({
      id: doc.id,
      ...data,
    });
  }
  return clubs;
};

const getClubPaymentInfo = async (clubId) => {
  const paymentInfoRef = doc(db, "clubs", clubId, "readonly", "payments");
  const paymentInfoDoc = await getDoc(paymentInfoRef);

  return paymentInfoDoc.data();
};

export { getClubs, getClub, getAdminClubs, getClubsByCity, getClubPaymentInfo };
