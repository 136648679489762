import { styled } from "../../theme/styled";

export const StyledTag = styled("span", {
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "16px",
  borderRadius: 16,
  padding: "0 8px",
  color: "$white",
  marginRight: 8,
  marginBottom: 6,
  variants: {
    color: {
      red: {
        gradient: "$red",
      },
      green: {
        gradient: "$green",
      },
      blue: {
        gradient: "$blue",
      },
      yellow: {
        gradient: "$yellow",
      },
    },
  },
});
