import React, { useEffect, useState } from "react";
import { useUserData } from "../../utils/userData";
import ModalUser from "../ModalUser/ModalUser";
import ModalAdmin from "../ModalAdmin/ModalAdmin";
import { getUser } from "../../firebase/users";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { useNavigate } from "react-router";

const TurnoModal = ({ handleClose, show, turno, canchas, club }) => {
  const user = useUserData();
  const userIsAdmin = !!club?.admins?.[user?.uid];
  const [userTurno, setUserTurno] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setUserTurno(undefined);
    if (turno?.user) {
      getUser(turno.user.id)
        .then((turnoUser) => {
          setUserTurno(turnoUser);
        })
        .catch(() => {
          console.error("Error al obtener el usuario");
        });
    } else {
      setUserTurno(undefined);
    }
  }, [turno?.user]);

  return (
    <Modal show={show} onClose={handleClose} size="md">
      {turno && userIsAdmin ? (
        <ModalAdmin
          key={`${turno?.hora}-${show}`}
          handleClose={handleClose}
          turno={turno}
          user={userTurno}
          canchas={canchas}
          club={club}
        ></ModalAdmin>
      ) : user && turno ? (
        <ModalUser
          key={`${turno?.hora}-${show}`}
          handleClose={handleClose}
          show={show}
          turno={turno}
          canchas={canchas}
          club={club}
        />
      ) : (
        <>
          <Modal.Header>
            <Modal.Title>Iniciar sesión</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Debes iniciar sesión para reservar un turno</p>
          </Modal.Body>
          <Modal.Footer align="center">
            <Button
              color="white"
              onClick={async () => {
                navigate("/signin");
              }}
            >
              <span>Iniciar Sesion</span>
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default TurnoModal;
