import { styled } from "../../theme/styled";
import { FiDownloadCloud } from "react-icons/fi";

export const StyledUpdateMessageMajor = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "fixed",
  maxWidth: "500px",
  width: "100%",
  height: "100%",
  backgroundColor: "$card",
  zIndex: 999999,
});

export const StyledIcon = styled(FiDownloadCloud, {
  fontSize: "96px",
  marginBottom: "24px",
  color: "$dark",
  opacity: 0.8,
});

export const StyledText = styled("span", {
  textAlign: "center",
  fontSize: "18px",
  maxWidth: "300px",
  color: "$dark",
});
