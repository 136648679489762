import React, { useState } from "react";
import Title from "../components/Title/Title";
import TextInput from "../components/TextInput/TextInput";
import Button from "../components/Button/Button";
import Paragraph from "../components/Paragraph/Paragraph";
import EmailSigninLayout from "../components/EmailSignin/EmailSigninLayout";
import { useNavigate } from "react-router";
import { forgotPassword, loginEmail } from "../utils/login";

const SinginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) return;

    if (!email.trim() || !password) {
      setError("Por favor complete todos los campos");
      return;
    }

    try {
      setLoading(true);
      await loginEmail(email.trim(), password);
    } catch (e) {
      console.log(e.code);
      let message = "Ocurrió un error al iniciar sesión, por favor intenta de nuevo.";
      switch (e.code) {
        case "auth/user-not-found":
          message =
            "El email ingresado no está registrado, por favor verifique que el email sea correcto o cree una cuenta.";
          break;
        case "auth/wrong-password":
          message =
            "La contraseña ingresada es incorrecta o su cuenta no tiene una contraseña, intente iniciar sesión con Google o Apple.";
          break;
        case "auth/invalid-email":
          message = "El email ingresado no es válido, por favor verifique que el email sea correcto.";
          break;

        default:
          break;
      }
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailSigninLayout>
      <EmailSigninLayout.Form onSubmit={handleLogin}>
        <input type="submit" hidden />
        <EmailSigninLayout.Title>
          <Title size="xxl">Iniciar Sesión</Title>
        </EmailSigninLayout.Title>
        <EmailSigninLayout.Input>
          <TextInput
            label={"Correo Electrónico"}
            name="email"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ingrese su email"
            disabled={loading}
          ></TextInput>
          <TextInput
            label={"Contraseña"}
            name="password"
            type="password"
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Ingrese su contraseña"
            disabled={loading}
          ></TextInput>
          <EmailSigninLayout.ErrorContainer>{error ? error : ""}</EmailSigninLayout.ErrorContainer>
          <EmailSigninLayout.FooterItem>
            <Button
              size="xs"
              onClick={async (e) => {
                e.preventDefault();
                // mostrar mensaje que solicite el email para enviar el link de recuperación
                // si el email existe tiene que revisar su casilla para cambiar la contraseña con el link
                const email = prompt(
                  "Ingrese su email para enviar el link de recuperación de contraseña, luego de aceptar recibirá un email con un link para cambiar su contraseña"
                )?.trim();
                try {
                  if (!email) return;
                  await forgotPassword(email);
                } catch (e) {
                  console.log(e);
                  setError("Ocurrió un error al enviar el email, por favor intenta de nuevo.");
                }
              }}
              color="linkWhite"
              disabled={loading}
            >
              ¿Olvidaste la contraseña?
            </Button>
          </EmailSigninLayout.FooterItem>
        </EmailSigninLayout.Input>
        <EmailSigninLayout.FooterItem>
          <Button color="primary" onClick={handleLogin} disabled={loading}>
            Iniciar Sesión
          </Button>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Paragraph margin="both" size="sm">
            Todavía no tenes una cuenta?
          </Paragraph>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Button onClick={() => navigate("/signup")} color="white" disabled={loading}>
            Registrarse
          </Button>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Button size="xs" onClick={() => navigate("/signin")} color="linkWhite" disabled={loading}>
            Ingresar con una cuenta de Google o Apple
          </Button>
        </EmailSigninLayout.FooterItem>
        <Paragraph size="sm" margin="both" align="center">
          Antes de registrarte puedes leer nuestras{" "}
          <EmailSigninLayout.PolicyLink to="/policy">Politicas de privacidad</EmailSigninLayout.PolicyLink>
        </Paragraph>
      </EmailSigninLayout.Form>
    </EmailSigninLayout>
  );
};

export default SinginPage;
