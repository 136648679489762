import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Container from "../components/Container/Container";
import InfoDireccion from "../components/InfoDireccion/InfoDireccion";
import InfoLayout from "../components/InfoLayout/InfoLayout";
import Map from "../components/Map/Map";
import Paragraph from "../components/Paragraph/Paragraph";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import Title from "../components/Title/Title";
import { getClub } from "../firebase/clubs";
import WhatsAppBtn from "../components/WhatsAppBtn/WhatsAppBtn";

const InfoPage = () => {
  const { clubId } = useParams();
  const [club, setClub] = useState(null);

  useEffect(() => {
    getClub(clubId)
      .then((data) => setClub(data))
      .catch(() => {
        console.error("Error al obtener el club");
      });
  }, [clubId]);

  return (
    <ScrollLayout>
      <ScrollLayout.FixedPart>
        <Container>
          <Breadcrumb
            pages={[
              { label: club?.name, url: `/club/${club?.id}`, name: "club" },
              { label: "Información", name: "info" },
            ]}
          ></Breadcrumb>
        </Container>
      </ScrollLayout.FixedPart>
      <ScrollLayout.ScrollPart noSpace>
        <Container>
          <InfoLayout.MapContainer>
            <Map club={club} />
          </InfoLayout.MapContainer>
          <InfoLayout.DireccionContainer>
            <InfoDireccion club={club} />
          </InfoLayout.DireccionContainer>
          {club?.phones && (
            <>
              <InfoLayout.InfoContainer>
                <Title size="lg">Telefono</Title>
                <Paragraph>{club?.phones?.join(" - ")}</Paragraph>
              </InfoLayout.InfoContainer>
              <InfoLayout.InfoContainer>
                <Title size="lg">Whatsapp</Title>
                <WhatsAppBtn phone={club.phones[0]}>{"Enviar mensaje"}</WhatsAppBtn>
              </InfoLayout.InfoContainer>
            </>
          )}
        </Container>
      </ScrollLayout.ScrollPart>
    </ScrollLayout>
  );
};

export default InfoPage;
