import React from "react";
import FutbolIcon from "../Icons/futbol";
import PadelIcon from "../Icons/padel";
import TenisIcon from "../Icons/tenis";
import TodosIcon from "../Icons/todos";

import { StyledSelectedBackground, StyledSportSelector, StyledSportSelectorItem } from "./SportSelector.styles";

const sportsAvailables = [
  {
    name: "all",
    label: "Todos",
    icon: TodosIcon,
  },
  {
    name: "padel",
    label: "Padel",
    icon: PadelIcon,
  },
  {
    name: "futbol",
    label: "Futbol",
    icon: FutbolIcon,
  },
  {
    name: "tenis",
    label: "Tenis",
    icon: TenisIcon,
  },
];

const SportSelector = ({ onSelect, value }) => {
  const selectedIndex = sportsAvailables.findIndex((sport) => sport.name === value);

  return (
    <StyledSportSelector>
      {sportsAvailables.map((sport, index) => (
        <StyledSportSelectorItem
          key={sport.name}
          onClick={() => {
            if (value !== sport.name) {
              onSelect(sport.name);
            } else {
              onSelect("all");
            }
          }}
          selected={value === sport.name}
        >
          <sport.icon />
          <span>{sport.label}</span>
        </StyledSportSelectorItem>
      ))}
      <StyledSelectedBackground
        style={{
          transform: `translateX(calc(${100 * selectedIndex}% + ${selectedIndex * 8}px))`,
        }}
        disabled={selectedIndex === -1}
      />
    </StyledSportSelector>
  );
};

export default SportSelector;
