import ReactMarkdown from "react-markdown";
import { styled } from "../../theme/styled";

export const StyledNotificationContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  shadow: 1,
  borderRadius: "16px",
  padding: "16px",
  variants: {
    read: {
      true: {
        color: "$disabled",
      },
    },
  },
});
export const StyledTextContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const StyledClubName = styled("span", {
  fontWeight: "700",
  fontSize: "16px",
  paddingRight: "4px",
});

export const StyledImg = styled("img", {
  width: "64px",
  height: "64px",
  objectFit: "cover",
  borderRadius: "50%",
  shadow: 1,
  flexShrink: 0,
  background: "$gray",
});

export const StyledDescription = styled(ReactMarkdown, {
  fontSize: "16px",
});

export const StyledHora = styled("p", {
  fontSize: "14px",
  paddingTop: "12px",
  variants: {
    read: {
      false: {
        color: "$primary",
      },
    },
  },
});

export const StyledRead = styled("div", {
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: "$primary",
  variants: {
    read: {
      true: { display: "none" },
    },
  },
});

export const StyledDescriptionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "0 12px",
});

export const StyledReadContainer = styled("div", {});
