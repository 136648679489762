import { styled } from "../../theme/styled";

export const StyledPeriodCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "250px",
  borderRadius: "8px",
  marginRight: "auto",
  marginLeft: "auto",
  shadow: 1,
  padding: "12px",
  flexShrink: 0,
  "&:last-child": {
    marginRight: "8px",
  },
});

export const StyledPeriodName = styled("span", {
  fontWeight: "700",
  fontSize: "22px",
  lineHeight: "27px",
  marginBottom: "18px",
});

export const StyledDetallesContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

export const StyledContainerTextAndTooltip = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

export const StyledDetalles = styled("span", {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  variants: {
    color: {
      green: {
        color: "$green",
        fontWeight: "700",
      },
      red: {
        color: "$red",
        fontWeight: "700",
      },
      dark: {
        color: "$dark",
        fontWeight: "700",
      },
    },
  },
});

export const StyledDetallesText = styled("span", {
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "22px",
});

export const StyledLastUpdate = styled("span", {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  marginTop: "18px",
});
