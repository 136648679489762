import { styled, withAlpha } from "../../theme/styled";

export const StyledAlert = styled("span", {
  display: "block",
  padding: "8px 16px",
  fontSize: 16,
  lineHeight: 1.5,
  variants: {
    color: {
      warn: {
        background: withAlpha("$yellow", 0.3),
        color: withAlpha("$dark", 0.7),
      },
    },
  },
});
