import { styled } from "../../theme/styled";

export const StyledText = styled("p", {
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "22px",
  marginBottom: 12,
});

export const StyledMsgUnirse = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});
