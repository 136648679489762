import React from "react";
import estadoPeriodo from "../../utils/estadoPeriodo";
import { monthYearStr } from "../../utils/fechas";
import { currencyFormatter } from "../../utils/money";
import Tooltip from "../Tooltip/Tooltip";
import {
  StyledContainerTextAndTooltip,
  StyledDetalles,
  StyledDetallesContainer,
  StyledDetallesText,
  StyledLastUpdate,
  StyledPeriodCard,
  StyledPeriodName,
} from "./PeriodCard.styles";

const PeriodCard = ({ period }) => {
  const precioTotal = period?.qty && period?.unitPrice ? period.qty * period.unitPrice : 0;
  const pendiente = precioTotal - (period?.totalPaid || 0);

  return (
    <StyledPeriodCard>
      <StyledPeriodName>{period ? monthYearStr(period.start) : ""}</StyledPeriodName>
      <StyledDetallesContainer>
        <StyledContainerTextAndTooltip>
          <StyledDetallesText>{"Turnos facturados"}</StyledDetallesText>
          <Tooltip
            text={
              "Los turnos facturados son los turnos que se jugaron mas los turnos que fueron cancelados con menos de media hora de antelación."
            }
          />
        </StyledContainerTextAndTooltip>
        <StyledDetalles>{period?.qty}</StyledDetalles>
      </StyledDetallesContainer>
      <StyledDetallesContainer>
        <StyledDetallesText>{"Precio por turno"}</StyledDetallesText>
        <StyledDetalles>{currencyFormatter.format(period?.unitPrice)}</StyledDetalles>
      </StyledDetallesContainer>
      <StyledDetallesContainer>
        <StyledDetallesText>{"Estado"}</StyledDetallesText>
        <StyledDetalles>{estadoPeriodo[period?.status]}</StyledDetalles>
      </StyledDetallesContainer>
      <StyledDetallesContainer>
        <StyledDetallesText>{"Total del periodo"}</StyledDetallesText>
        <StyledDetalles color={"dark"}>{currencyFormatter.format(precioTotal)}</StyledDetalles>
      </StyledDetallesContainer>
      {period?.status === "open" ? (
        <StyledLastUpdate>
          {period.lastUpdateTime
            ? `Actualizado por última vez el ${period.lastUpdateTime.toDate().toLocaleString("es-AR")}hs`
            : null}
        </StyledLastUpdate>
      ) : (
        <StyledDetallesContainer>
          <StyledDetallesText>{"Saldo pendiente"}</StyledDetallesText>
          <StyledDetalles color={pendiente > 0 ? "red" : "green"}>{currencyFormatter.format(pendiente)}</StyledDetalles>
        </StyledDetallesContainer>
      )}
    </StyledPeriodCard>
  );
};

export default PeriodCard;
