import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from ".";

const createLink = async (enlace, title, clubId) => {
  const link = {
    link: enlace,
    title: title,
    clubId: clubId,
  };
  const linksRef = collection(db, "clubs", clubId, "links");
  await addDoc(linksRef, link);
};

const getLinks = async (clubId) => {
  const linksRef = collection(db, "clubs", clubId, "links");
  const linksSnapshot = await getDocs(linksRef);
  const links = linksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return links;
};

const onLinksSnapshot = (clubId, onChange) => {
  const linksRef = collection(db, "clubs", clubId, "links");
  const unsubscribe = onSnapshot(linksRef, onChange);
  return unsubscribe;
};

const deleteLink = async (id, clubId) => {
  const linkRef = doc(db, "clubs", clubId, "links", id);
  await deleteDoc(linkRef);
};

const updateLink = async (id, enlace, title, clubId) => {
  const linkRef = doc(db, "clubs", clubId, "links", id);
  await updateDoc(linkRef, {
    link: enlace,
    title: title,
  });
};

export { createLink, deleteLink, getLinks, onLinksSnapshot, updateLink };
