import { Link } from "react-router-dom";
import { styled } from "../../theme/styled";

export const StyledAccountLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const StyledNameContainer = styled("div", {
  margin: "0 auto",
});

export const StyledInputContainer = styled("div", {
  padding: "24px 16px",
});

export const StyledNotifTitleContainer = styled("div", {
  borderTop: "1px solid $light",
  padding: "20px 48px 0 48px",
  textAlign: "center",
  justifyContent: "space-around",
  h6: {
    margin: 0,
  },
  p: {
    marginTop: 8,
    marginBottom: 0,
  },
});

export const StyledNotifContainer = styled("div", {
  padding: "0 16px 8px 16px",
  display: "flex",
  justifyContent: "space-around",
});

export const StyledLogoutContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  paddingTop: "24px",
  borderTop: "1px solid $light",
});

export const StyledPolicyLink = styled(Link, {
  color: "$dark",
  textAlign: "center",
  marginTop: "32px",
  marginBottom: "24px",
});

export const StyledRemoveAccountLink = styled("button", {
  color: "$dark",
  textAlign: "center",
  marginBottom: "24px",
  border: 0,
  background: "none",
  fontSize: "16px",
  textDecoration: "underline",
  cursor: "pointer",
});
