import React from "react";
import estadoTurno from "../../utils/estadoTurno";

import { StyledEstado, StyledHora, StyledName, StyledTurnoCard, StyledHoraFin } from "./TurnoCard.styles";
import FakeComponent from "../FakeComponent/FakeComponent";

const TurnoCard = ({ estado, name, hora, horaFin, onClick, loading, cancha }) => {
  return (
    <StyledTurnoCard onClick={onClick} loading={loading}>
      {loading ? (
        <FakeComponent style={{ width: "140px", height: "24px", margin: "12px" }} />
      ) : (
        <StyledHora>
          {hora}
          {!loading && <StyledHoraFin>a {horaFin}</StyledHoraFin>}
        </StyledHora>
      )}
      {loading ? (
        <FakeComponent style={{ width: "140px", height: "24px", position: "absolute", top: "12px", right: "12px" }} />
      ) : (
        <StyledEstado estado={estado}>{estadoTurno[estado]}</StyledEstado>
      )}
      <StyledName>
        {!!cancha ? `${cancha} - ` : ""}
        {name}
      </StyledName>
    </StyledTurnoCard>
  );
};

export default TurnoCard;
