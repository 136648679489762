import React from "react";
import {
  StyledCloseButton,
  StyledContainerModal,
  StyledFondoModal,
  StyledTitleModal,
  StyeledModalHeader,
  StyledModalBody,
  StyledModalFooter,
} from "./Modal.styles";

const Modal = ({ onClose, show, children, size = "sm" }) => {
  return (
    <>
      <StyledFondoModal show={show} onClick={onClose}></StyledFondoModal>
      <StyledContainerModal show={show} size={size}>
        <StyledCloseButton onClick={onClose}>x</StyledCloseButton>
        {children}
      </StyledContainerModal>
    </>
  );
};

Modal.Title = StyledTitleModal;
Modal.Header = StyeledModalHeader;
Modal.Body = StyledModalBody;
Modal.Footer = StyledModalFooter

export default Modal;
