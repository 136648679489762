import { useEffect, useRef, useState } from "react";
import { onTurnosSnapshot } from "../firebase/turnos";
import { useUserData } from "../utils/userData";
import { updateUserCache } from "../utils/turno";
import useStaticHandler from "./useStaticHandler";

const useTurnosByCanchas = (club, canchas, fechaSeleccionada) => {
  const user = useUserData();
  const [turnosDisponibles, setTurnosDisponibles] = useState(null);
  const [turnosOcupados, setTurnosOcupados] = useState([]);
  const [users, setUsers] = useState({});
  const turnosPosiblesRef = useRef({});
  const turnosOcupadosRef = useRef({});
  const userIsAdmin = !!club?.admins?.[user?.uid];
  const usersCache = useRef({});

  const getTurnos = useStaticHandler(() => {
    turnosPosiblesRef.current = {};
    turnosOcupadosRef.current = {};
    setTurnosDisponibles(null);
    setTurnosOcupados([]);
    if (!club || !canchas || !fechaSeleccionada || !canchas.length) {
      return;
    }
    const unsubscribes = [];

    for (const cancha of canchas) {
      let fechaActual = new Date(fechaSeleccionada);
      fechaActual = new Date(fechaActual.getTime() + fechaActual.getTimezoneOffset() * 60000);
      const diaSemana = fechaActual.getDay();
      const horarioDia = cancha.horarios?.[diaSemana] || [];
      const turnosPosibles = horarioDia.map((horario) => ({
        estado: "DISPONIBLE",
        name: "",
        hora: horario.start,
        horaFin: horario.end,
        fecha: fechaSeleccionada,
        canchaId: cancha.id,
      }));

      turnosPosiblesRef.current[cancha.id] = turnosPosibles;

      const unsubscribe = onTurnosSnapshot(club.id, cancha.id, fechaSeleccionada, async (snapshot) => {
        const turnosOcupadosCancha = snapshot.docs.map((doc) => {
          const turno = doc.data();
          return {
            id: doc.id,
            ...turno,
            canchaIds: [cancha.id],
            horaFin: horarioDia.find((horario) => horario.start === turno.hora)?.end,
          };
        });
        turnosOcupadosRef.current[cancha.id] = turnosOcupadosCancha;

        const turnosPosiblesTotal = Object.values(turnosPosiblesRef.current).flat();
        if (userIsAdmin) {
          for (const turno of turnosOcupadosCancha) {
            await updateUserCache(usersCache, turno, user, userIsAdmin);
          }
        }

        const turnosOcupadosTotal = [];

        for (const canchaId in turnosOcupadosRef.current) {
          turnosOcupadosTotal.push(...turnosOcupadosRef.current[canchaId].map((turno) => ({ ...turno, canchaId })));
        }

        setUsers({ ...usersCache.current });
        setTurnosDisponibles(
          turnosPosiblesTotal.filter(
            (posible) =>
              !turnosOcupadosTotal.find(
                (ocupado) => ocupado.hora === posible.hora && ocupado.canchaId === posible.canchaId
              )
          )
        );
        setTurnosOcupados(
          turnosOcupadosTotal.filter((turno) => userIsAdmin || (turno.user?.id && turno.user?.id === user?.uid))
        );
      });
      unsubscribes.push(unsubscribe);
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  });

  useEffect(() => {
    return getTurnos();
  }, [club, canchas, fechaSeleccionada, user?.uid, getTurnos]);

  return { turnosDisponibles, turnosOcupados, users };
};

export default useTurnosByCanchas;
