import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from ".";

const getInscripciones = async (clubId) => {
  const q = query(collection(db, `clubs/${clubId}/inscripciones`));
  const querySnapshot = await getDocs(q);
  const inscripciones = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    inscripciones.push({
      id: doc.id,
      ...data,
    });
  }
  return inscripciones;
};

const getInscripcion = async (clubId, inscripcionId) => {
  const inscripcionRef = doc(db, `clubs/${clubId}/inscripciones`, inscripcionId);
  const inscripcionDoc = await getDoc(inscripcionRef);
  let result;
  if (inscripcionDoc.exists()) {
    result = {
      id: inscripcionDoc.id,
      ...inscripcionDoc.data(),
    };
  } else {
    result = undefined;
  }
  return result;
};

export { getInscripcion, getInscripciones };
