import React from "react";
import NoInternet from "../components/NoInternet/NoInternet";
import OfflineLayaut from "../components/OfflineLayaut/OfflineLayaut";

const OfflinePage = () => {
  return (
    <OfflineLayaut>
      <NoInternet></NoInternet>
    </OfflineLayaut>
  );
};

export default OfflinePage;
