import { styled } from "../../theme/styled";

export const StyledFacturacionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const StyledTitleFacturacion = styled("span", {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "27px",
  marginTop: "24px",
  marginBottom: "24px",
});

export const StyledCardContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
  padding: "16px",
  gap: "16px",
});
