import { FiX } from "react-icons/fi";
import { styled } from "../../theme/styled";

export const StyledActionAlert = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  borderRadius: "12px",
  shadow: 2,
  padding: "20px 16px",
  position: "fixed",
  maxWidth: "468px",
  width: "calc(100% - 20px)",
  bottom: "88px",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "$white",
  transition: "none 0.2s ease-in-out",
  transitionProperty: "opacity, visibility",
  opacity: 1,
  visibility: "visible",
  zIndex: 20,
  svg: {
    fontSize: "24px",
    color: "$gray",
    flexShrink: 0,
  },
  variants: {
    visible: {
      false: { opacity: 0, visibility: "hidden" },
    },
  },
});

export const StyledSpan = styled("span", {
  fontSize: "14px",
  margin: "0 12px",
});

export const StyledContainerCloseButton = styled("div", {
  marginLeft: "12px",
});

export const StyledCloseButton = styled(FiX, {
  width: "20px",
  height: "20px",
  cursor: "pointer",
});
