import { useEffect, useMemo, useRef, useState } from "react";
import { onTurnosSnapshot } from "../firebase/turnos";
import {
  removeTurnoCache,
  updateTurnoCache,
  updateUserCache,
} from "../utils/turno";
import { useUserData } from "../utils/userData";

const useGetTurnos = (club, cancha, fechaSeleccionada) => {
  const user = useUserData();

  const [turnos, setTurnos] = useState();
  const [users, setUsers] = useState({});

  const usersCache = useRef({});
  const turnosCache = useRef([]);

  const userIsAdmin = useMemo(
    () => !!club?.admins?.[user?.uid],
    [club?.admins, user?.uid]
  );

  useEffect(() => {
    turnosCache.current = [];

    let unsubscribe;

    if (club && cancha && fechaSeleccionada) {
      unsubscribe = onTurnosSnapshot(
        club?.id,
        cancha?.id,
        fechaSeleccionada,
        async (snapshot) => {
          for (const change of snapshot.docChanges()) {
            if (change.type === "added") {
              const turno = {
                id: change.doc.id,
                ...change.doc.data(),
              };
              updateTurnoCache(turnosCache, turno, true);
              await updateUserCache(usersCache, turno, user, userIsAdmin);
            }
            if (change.type === "modified") {
              const turno = {
                id: change.doc.id,
                ...change.doc.data(),
              };
              updateTurnoCache(turnosCache, turno);
              await updateUserCache(usersCache, turno, user, userIsAdmin);
            }
            if (change.type === "removed") {
              removeTurnoCache(turnosCache, change.doc.id);
            }
          }

          setTurnos([...turnosCache.current]);
          setUsers({ ...usersCache.current });
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [cancha, club, fechaSeleccionada, user, userIsAdmin]);

  return { turnos, users, setTurnos };
};

export default useGetTurnos;
