import React, { useState } from "react";
import {
  StyledButton,
  StyledButtonContainer,
  StyledButtonSelect,
  StyledButtonSelectOption,
  StyledButtonSelectOptionItem,
  StyledButtonSelectOptionsContainer,
  StyledOptions,
} from "./ButtonSelect.styles";

const ButtonSelect = ({ options, value, onClick, onClickOption }) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <StyledButtonSelect>
      <StyledButtonContainer>
        <StyledButton onClick={onClick}>{value}</StyledButton>
        <StyledButtonSelectOptionsContainer onClick={() => setShowOptions(!showOptions)}>
          <StyledOptions />
        </StyledButtonSelectOptionsContainer>
      </StyledButtonContainer>
      <StyledButtonSelectOption show={showOptions}>
        {options.map((option) => (
          <StyledButtonSelectOptionItem
            key={option?.name}
            onClick={() => {
              onClickOption(option);
              setShowOptions(false);
            }}
          >
            {option.label}
          </StyledButtonSelectOptionItem>
        ))}
      </StyledButtonSelectOption>
    </StyledButtonSelect>
  );
};

export default ButtonSelect;
