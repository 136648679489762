import { useEffect } from "react";
import { useNavigate } from "react-router";
import useAccountVerified from "./useAccountVerified";

const useAccountReady = (user, addAlert) => {
  const navigate = useNavigate();

  const accountVerified = useAccountVerified();

  const userReady = user && user?.displayName && user?.phone && accountVerified;
  const currentPath = window.location.pathname;
  const isAccountPage = currentPath === "/account";
  const isOfflinePage = currentPath === "/offline";

  useEffect(() => {
    if (user && !userReady) {
      if (navigator.onLine && !isAccountPage) {
        navigate("/account");
        if (!accountVerified) {
          addAlert({
            type: "toast",
            message: "Por favor verifique su cuenta antes de continuar",
            time: 10000,
          });
        } else {
          addAlert({
            type: "toast",
            message: "Por favor complete y guarde su perfil antes de continuar",
          });
        }
      } else if (!navigator.onLine && !isOfflinePage) {
        navigate("/offline");
      }
    }
  }, [isAccountPage, navigate, user, userReady, addAlert, isOfflinePage, accountVerified]);
};

export default useAccountReady;
