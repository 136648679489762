import React, { useContext, useMemo } from "react";
import { NotificationsContext } from "../../utils/notifications";
import { StyledCount, StyledIcon, StyledNotificationContainer } from "./NotificationIcon.styles";

const NotificationIcon = () => {
  const notifications = useContext(NotificationsContext);
  const count = useMemo(() => notifications.filter((e) => !e.read).length, [notifications]);
  return (
    <StyledNotificationContainer>
      <StyledIcon />
      {count > 0 && <StyledCount>{count > 9 ? "9+" : count}</StyledCount>}
    </StyledNotificationContainer>
  );
};

export default NotificationIcon;
