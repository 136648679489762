import React from "react";
import { StyledIcon, StyledWhatsAppBtn } from "./WhatsAppBtn.styles";

const WhatsAppBtn = ({ phone, message, onRedirect, children }) => {
  const phoneNumber = phone.replace(/[^\d]/g, "");
  const url = message ? `https://wa.me/${phoneNumber}?text=${message}` : `https://wa.me/${phoneNumber}`;

  return (
    <StyledWhatsAppBtn
      onClick={(e) => {
        window.open(url, "_blank");
        onRedirect?.(e);
      }}
    >
      <StyledIcon />
      {children}
    </StyledWhatsAppBtn>
  );
};

export default WhatsAppBtn;
