import React from "react";
import {
  StyledActionsContainer,
  StyledHeader,
  StyledHeaderActionButton,
  StyledHeaderTitle,
  StyledText,
  StyledContainerButton,
  StyledLocationContainer,
  StyledTextLoc,
  StyledButtonLoc,
  StyledButtonLocation,
  StyledLogo,
} from "./Header.styles";
import { FiChevronDown } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import FakeComponent from "../FakeComponent/FakeComponent";

const selectedLanguage = "es";

const Header = ({ title, actions, showLocation, onLocationClick, selectedCity, cities, showLogo }) => (
  <StyledHeader>
    {showLogo && <StyledLogo src="/img/logo-header.svg" alt="logo"/>}
    <StyledHeaderTitle>{title}</StyledHeaderTitle>
    {showLocation && cities[selectedLanguage]?.[selectedCity] ? (
      <StyledLocationContainer onClick={onLocationClick}>
        <StyledButtonLocation>
          <GoLocation />
        </StyledButtonLocation>
        <StyledTextLoc>{cities[selectedLanguage]?.[selectedCity]}</StyledTextLoc>
        <StyledButtonLoc>
          <FiChevronDown />
        </StyledButtonLoc>
      </StyledLocationContainer>
    ) : showLocation ? (
      <FakeComponent style={{ width: 156, height: 26 }} />
    ) : null}
    <StyledActionsContainer>
      {actions
        ?.filter((e) => e.visible)
        .map((action) => (
          <StyledContainerButton key={action.name} onClick={action.onClick}>
            <StyledHeaderActionButton>
              <action.icon />
            </StyledHeaderActionButton>
            {action.text && <StyledText>{action.text}</StyledText>}
          </StyledContainerButton>
        ))}
    </StyledActionsContainer>
  </StyledHeader>
);

export default Header;
