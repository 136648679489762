import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from ".";

const getCanchas = async (clubId) => {
  const querySnapshot = await getDocs(collection(db, "clubs", clubId, "canchas"));
  const canchas = [];

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    canchas.push({
      id: doc.id,
      clubId,
      ...data,
    });
  }
  return canchas;
};

const getCancha = async (clubId, canchaId) => {
  const canchaRef = doc(db, "clubs", clubId, "canchas", canchaId);
  const canchaDoc = await getDoc(canchaRef);
  let result;
  if (canchaDoc.exists()) {
    result = {
      id: canchaDoc.id,
      ...canchaDoc.data(),
    };
  } else {
    result = undefined;
  }
  return result;
};

export { getCanchas, getCancha };
