import { styled } from "../../theme/styled";
import { MdExpandMore } from "react-icons/md";
import { FiRepeat } from "react-icons/fi";

export const StyledCardAdmin = styled("div", {
  userSelect: "none",
  color: "$dark",
  position: "relative",
  padding: 12,
  display: "flex",
  overflow: "hidden",
  flexDirection: "column",
  marginBottom: 12,
  borderRadius: 16,
  shadow: 1,
  transition: "height 0.1s ease-in-out",
  variants: {
    isOpen: {
      true: {
        height: 167,
      },
      false: {
        height: 82,
      },
    },
  },
});

export const StyledHoraEstadoRow = styled("div", {
  display: "flex",
  marginBottom: 8,
});

export const StyledCardHora = styled("p", {
  fontWeight: 700,
  lineHeight: "27px",
  flex: 1,
  variants: {
    size: {
      sm: {
        fontSize: 16,
      },
      md: {
        fontSize: 20,
      },
    },
  },
});

export const StyledHoraFin = styled("span", {
  fontWeight: 500,
  fontSize: 16,
  color: "$dark",
  lineHeight: "27px",
  marginLeft: 4,
});

export const StyledCardEstado = styled("p", {
  textAlign: "right",
  fontWeight: 700,
  lineHeight: "27px",
  flexGrow: 0,
  variants: {
    estado: {
      OCUPADO: {
        color: "$primary",
      },
      DISPONIBLE: {
        color: "$secondary",
      },
      PENDIENTE: {
        color: "$yellow",
      },
      DESACTIVADO: {
        color: "$gray",
      },
      ELIMINADO: {
        color: "$gray",
      },
    },
    size: {
      sm: {
        fontSize: 16,
      },
      md: {
        fontSize: 20,
      },
    },
  },
});

export const StyledCardUser = styled("div", {});

export const StyledOpenButton = styled(MdExpandMore, {
  width: 24,
  height: 24,
  position: "absolute",
  bottom: "8px",
  right: "8px",
  transition: "transform 0.1s ease-in-out",
  variants: {
    isOpen: {
      true: { transform: "rotate(180deg)" },
    },
  },
});

export const StyledUserContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const StyledUserData = styled("p", {
  transition: "opacity 0.1s ease-in-out",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  variants: {
    size: {
      sm: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "19px",
      },
      md: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "22px",
      },
    },
    isOpen: {
      false: {
        opacity: 0,
      },
    },
  },
});

export const StyledTurnoContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  paddingTop: 8,
});

export const StyledTurnoData = styled("p", {
  transition: "opacity 0.1s ease-in-out",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  variants: {
    size: {
      sm: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "19px",
      },
      md: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "22px",
      },
    },
    isOpen: {
      false: {
        opacity: 0,
      },
    },
  },
});

export const StyledUserImg = styled("img", {
  width: 60,
  height: 60,
  borderRadius: "12px",
  transition: "none 0.1s ease-in-out",
  transitionProperty: "width, margin",
  objectFit: "cover",
  variants: {
    isOpen: {
      false: {
        width: 0,
      },
      true: {
        marginRight: 12,
      },
    },
  },
});

export const StyledContainerData = styled("div", {
  width: "calc(100% - 80px)",
});

export const StyledInput = styled("input", {
  width: "100%",
  border: "none",
  backgroundColor: "transparent",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "22px",
  borderBottom: "1px solid $gray",
  outline: "none",
  color: "$dark",
  "&:focus": {
    borderColor: "$dark",
  },
  "&:disabled": {
    borderColor: "transparent",
  },
});

export const StyledFijoIcon = styled(FiRepeat, {
  color: "$dark",
  position: "relative",
  top: "3px",
  left: "-12px",
});
