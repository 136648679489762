import React, { useContext, useState } from "react";
import Title from "../components/Title/Title";
import EmailSigninLayout from "../components/EmailSignin/EmailSigninLayout";
import { FaApple } from "react-icons/fa";
import Button from "../components/Button/Button";
import { useNavigate } from "react-router";
import { login } from "../utils/login";
import { AlertsContext } from "../utils/alerts";
import Paragraph from "../components/Paragraph/Paragraph";
import { appleProvider } from "../firebase";

const SinginPage = () => {
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);

  return (
    <EmailSigninLayout align="center">
      <EmailSigninLayout.Form onSubmit={(e) => e.preventDefault()}>
        <input type="submit" hidden />
        <EmailSigninLayout.Title>
          <Title size="xxl">Iniciar Sesión</Title>
        </EmailSigninLayout.Title>
        <EmailSigninLayout.FooterItem>
          <Button
            color="white"
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await login();
              } catch {
                addAlert({
                  type: "toast",
                  message: "Ocurrió un error al iniciar sesión, por favor intenta de nuevo.",
                  time: 3000,
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <img src="/img/google-icon.svg" style={{ marginRight: "10px" }} width="24px" alt="" />
            <span>Iniciar con Google</span>
          </Button>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Button
            color="white"
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await login(appleProvider);
              } catch (e) {
                console.log(e);
                addAlert({
                  type: "toast",
                  message: "Ocurrió un error al iniciar sesión, por favor intenta de nuevo.",
                  time: 3000,
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            <FaApple />
            <span style={{ marginLeft: 6 }}>Continuar con Apple</span>
          </Button>
        </EmailSigninLayout.FooterItem>
        <EmailSigninLayout.FooterItem>
          <Button size="xs" onClick={() => navigate("/email-signin")} color="linkWhite">
            Ingresar con email y contraseña
          </Button>
        </EmailSigninLayout.FooterItem>
        <Paragraph size="sm" margin="both" align="center">
          Antes de registrarte puedes leer nuestras{" "}
          <EmailSigninLayout.PolicyLink to="/policy">Politicas de privacidad</EmailSigninLayout.PolicyLink>
        </Paragraph>
      </EmailSigninLayout.Form>
    </EmailSigninLayout>
  );
};

export default SinginPage;
