import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Container from "../components/Container/Container";
import ScrollLayout from "../components/ScrollLayout/ScrollLayout";
import SmallLoader from "../components/SmallLoader/SmallLoader";
import { getClub } from "../firebase/clubs";
import { getInscripcion } from "../firebase/inscripciones";
import Iframe from "../components/Iframe/Iframe";

const InscripcionPage = () => {
  const { clubId, inscripcionId } = useParams();
  const [club, setClub] = useState(null);
  const [inscripcion, setInscripcion] = useState(null);

  useEffect(() => {
    if (clubId && !club) {
      getClub(clubId)
        .then((data) => setClub(data))
        .catch(() => console.error("Error al obtener el club"));
    }
    if (inscripcionId && !inscripcion) {
      getInscripcion(clubId, inscripcionId)
        .then((data) => setInscripcion(data))
        .catch(() => console.error("Error al obtener la inscripcion"));
    }
  }, [club, clubId, inscripcion, inscripcionId]);

  const loading = !club || !inscripcion;

  if (loading) return <SmallLoader visible />;

  return (
    <ScrollLayout>
      <ScrollLayout.FixedPart>
        <Container>
          <Breadcrumb
            pages={[
              { label: club?.name, url: `/club/${club?.id}`, name: "club" },
              { label: inscripcion.name, name: "inscripcion" },
            ]}
          ></Breadcrumb>
        </Container>
      </ScrollLayout.FixedPart>
      <ScrollLayout.ScrollPart>
        <Iframe src={inscripcion.link} title={inscripcion.name} />
      </ScrollLayout.ScrollPart>
    </ScrollLayout>
  );
};

export default InscripcionPage;
