const saveCache = (cacheName, value, local = false) => {
  if (local) {
    localStorage.setItem(cacheName, JSON.stringify(value));
  } else {
    sessionStorage.setItem(cacheName, JSON.stringify(value));
  }
};

const getCache = (cacheName, local = false) => {
  if (local) {
    return JSON.parse(localStorage.getItem(cacheName));
  }
  return JSON.parse(sessionStorage.getItem(cacheName));
};

export { saveCache, getCache };
