import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from ".";

const createUser = (uid, data) => {
  const userRef = doc(db, "users", uid);
  return setDoc(userRef, data);
};

const updateUser = (uid, data) => {
  if (!uid) {
    throw new Error("No se puede actualizar un usuario sin UID");
  }

  const userRef = doc(db, "users", uid);
  return setDoc(userRef, data, { merge: true });
};

const getUserRef = (uid) => {
  if (!uid) {
    throw new Error("No se puede obtener un usuario sin UID");
  }

  return doc(db, "users", uid);
};

const getUser = async (uid) => {
  const userRef = getUserRef(uid);
  const userDoc = await getDoc(userRef);

  const result = {
    uid: userDoc.id,
    ...userDoc.data(),
  };
  return result;
};

export { createUser, updateUser, getUserRef, getUser };
