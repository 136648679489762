import { styled } from "../../theme/styled";

const sportItemWidth = 86;

export const StyledSportSelector = styled("div", {
  height: 80,
  position: "relative",
  borderRadius: "16px 0 0 16px",
  backgroundColor: "$card",
  marginLeft: 16,
  marginTop: 8,
  shadow: 1,
  display: "flex",
  overflowX: "overlay",
  overflowY: "hidden",
  userSelect: "none",
});

export const StyledSportSelectorItem = styled("div", {
  width: sportItemWidth,
  height: 80,
  borderRadius: 16,
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  justifyContent: "space-between",
  padding: 12,
  transition: "all 0.2s ease-in-out",
  transitionProperty: "color, background",
  zIndex: 10,
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 600,
  cursor: "pointer",
  "&:not(:first-child)": {
    marginLeft: 8,
  },
  variants: {
    selected: {
      true: {
        color: "$white",
      },
      false: {
        color: "$dark",
      },
    },
  },
});

export const StyledSelectedBackground = styled("div", {
  width: sportItemWidth,
  height: 80,
  borderRadius: 16,
  backgroundColor: "$primary",
  gradient: "$primary",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  transition: "all 0.2s ease-in-out",
  transitionProperty: "transform, opacity",
  variants: {
    disabled: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
  },
});
