import React, { useEffect, useState } from "react";
import { createTurno } from "../../firebase/turnos";
import { useUserData } from "../../utils/userData";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import { formatDate } from "../../utils/fechas";
import Select from "../Select/Select";
import showError from "../../utils/error";

const ModalUser = ({ handleClose, show, turno, canchas, club }) => {
  const user = useUserData();
  const [loading, setLoading] = useState(true);
  const [cancha, setCancha] = useState(canchas[0]);
  const fechaStr = formatDate(turno.fecha);

  const reservarTurno = async () => {
    if (
      canchas.length === 1 ||
      window.confirm(
        `¿Está seguro que deseas reservar el turno de las ${turno?.hora} del día ${fechaStr} en ${cancha?.name} de ${club?.name}?`
      )
    ) {
      setLoading(true);
      const newTurno = {
        userId: user.uid,
        fecha: turno.fecha,
        hora: turno.hora,
        estado: "PENDIENTE",
        diaDePedido: new Date(),
      };
      try {
        await createTurno(club.id, cancha.id, newTurno);
      } catch (error) {
        showError(error);
      }

      handleClose();
    }
  };

  useEffect(() => {
    if (show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>Reservar turno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {canchas.length === 1 ? (
          <Paragraph>
            Solo queda un turno disponible en <b>{cancha?.name}</b> de <b>{club?.name}</b>. ¿Estás seguro de que deseas
            reservar el turno de las <b>{turno?.hora}</b> del día <b>{fechaStr}</b>?
          </Paragraph>
        ) : (
          <>
            <Paragraph>
              El turno de las <b>{turno?.hora}</b> del día <b>{fechaStr}</b> en <b>{club?.name}</b> esta disponible en
              las siguientes canchas:
            </Paragraph>
            <Select
              options={canchas.map((c) => ({ value: c.id, label: c.name }))}
              value={cancha.id}
              onChange={(e) => setCancha(canchas.find((c) => c.id === e.target.value))}
              disabled={loading}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer align="right">
        {user && (
          <Button color="primary" disabled={loading} onClick={reservarTurno}>
            {loading ? "Reservando..." : "Reservar"}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default ModalUser;
