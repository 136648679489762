import { styled } from "../../theme/styled";
import { FiX } from "react-icons/fi";

export const StyledFondoModal = styled("div", {
  backgroundColor: "rgba(0,0,0,0.5)",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1000,
  transition: "none 0.2s ease-in-out",
  transitionProperty: "opacity, visibility",
  opacity: 1,
  visibility: "visible",
  variants: {
    show: {
      false: {
        opacity: 0,
        visibility: "hidden",
      },
    },
  },
});

export const StyledContainerModal = styled("div", {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: "16px",
  shadow: 1,
  position: "fixed",
  zIndex: 1010,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "none 0.2s ease-in-out",
  transitionProperty: "opacity, visibility",
  opacity: 1,
  visibility: "visible",
  variants: {
    size: {
      sm: {
        width: "328px",
      },
      md: {
        width: "360px",
      },
    },
    show: {
      false: {
        opacity: 0,
        visibility: "hidden",
      },
    },
  },
});

export const StyledCloseButton = styled(FiX, {
  color: "$dark",
  position: "absolute",
  top: "16px",
  right: "16px",
  cursor: "pointer",
});

export const StyledTitleModal = styled("h1", {
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "27px",
  padding: "12px 16px",
});

export const StyeledModalHeader = styled("div", {});

export const StyledModalBody = styled("div", {
  paddingBottom: "12px",
  paddingLeft: "16px",
  paddingRight: "16px",
});

export const StyledModalFooter = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "16px",
  paddingLeft: "16px",
  paddingRight: "16px",
  variants: {
    align: {
      center: {
        justifyContent: "center",
      },
      left: {
        justifyContent: "flex-start",
      },
      right: {
        justifyContent: "flex-end",
      },
    },
  },
});
